import { Asset } from '@ynomia/core/dist/project/assets';
import { createSelector } from 'reselect';
import {
  getAssetManagerFilters,
  getAssetTypes,
  getFilteredAssets,
  getFilteredAssetsContext,
} from '..';
import map from '../map';

const getFilteredAssetsForDigitalTwin = createSelector(
  [
    getAssetManagerFilters,
    getFilteredAssetsContext,
  ],
  (
    assetManagerFilters,
    filteredAssetsContext,
  ) => {
    const {
      searchTags,
      searchText,
      dateFilter,
      typeFilter,
      assetStatusFilter,
      observationTypeFilter,
      selectedLayerIds,
      tableFilter,
      openedLayerId,
      supplierFilter,
      multiSearchModeFilter,
    } = assetManagerFilters;
    return getFilteredAssets(filteredAssetsContext, {
      searchTags,
      searchText,
      dateFilter,
      typeFilter,
      assetStatusFilter,
      observationTypeFilter,
      selectedLayerIds,
      tableFilter,
      openedLayerId,
      supplierFilter,
      multiSearchModeFilter,
    });
  },
);

const getFilteredAssetsForTwin = createSelector(
  [
    getAssetManagerFilters,
    getFilteredAssetsContext,
  ],
  (
    assetManagerFilters,
    filteredAssetsContext,
  ) => {
    const { openedLayerId } = assetManagerFilters;
    return getFilteredAssets(filteredAssetsContext, {
      openedLayerId,
    });
  },
);

export const getTwinIDModelIdMap = createSelector(
  [map.models],
  (models) => {
    const individualModels = models?.forge?.settings?.individualModels;
    if (!individualModels) return {};
    const output: { [TwinID: string]: string } = {};
    Object.keys(individualModels).forEach((modelId) => {
      const model = individualModels[modelId];
      const { mapping } = model;
      Object.keys(mapping).forEach((twinID) => {
        output[twinID] = modelId || '';
      });
    });
    return output;
  },
);

export const getFilteredModelIds = createSelector(
  [getTwinIDModelIdMap, getFilteredAssetsForTwin],
  (twinIDModelIdMap, assets) => {
    const modelIdsSet = new Set<string>();
    assets.forEach((asset) => {
      const twinID = asset?.destination?.twinID?.[0];
      if (!twinID || !twinIDModelIdMap[twinID]) return;
      modelIdsSet.add(twinIDModelIdMap[twinID]);
    });
    return modelIdsSet.size ? [...modelIdsSet] : undefined;
  },
);

export const getFilteredAssetsKeyedByStatusForDigitalTwin = createSelector(
  [getAssetTypes, getFilteredAssetsForDigitalTwin],
  (assetTypes, filteredAssets) => {
    const assetKeyedByStatuses = new Map();

    if (assetTypes && filteredAssets) {
      const statusesArray = assetTypes.map(assetType => assetType.statuses!).flat();

      statusesArray?.forEach(({ id }) => {
        const assetsFilteredByStatusId = filteredAssets.filter(({ status }) => status === id);
        assetKeyedByStatuses.set(id, assetsFilteredByStatusId);
      });
    }

    return assetKeyedByStatuses as Map<string, Array<Asset>>;
  },
);

export const getSelectedAssetsKeyedByStatusForDigitalTwin = createSelector(
  [getAssetTypes, getFilteredAssetsForDigitalTwin],
  (assetTypes, filteredAssets) => {
    const assetKeyedByStatuses = new Map();

    if (assetTypes && filteredAssets) {
      const statusesArray = assetTypes.map(assetType => assetType.statuses!).flat();

      statusesArray?.forEach(({ id }) => {
        const assetsFilteredByStatusId = filteredAssets.filter(({ status }) => status === id);
        assetKeyedByStatuses.set(id, assetsFilteredByStatusId);
      });
    }

    return assetKeyedByStatuses as Map<string, Array<Asset>>;
  },
);
