import { Observable, Subscription } from 'rxjs';
import { InMemoryCacheData } from '@ynomia/client';
import { ContextDispatch } from '../../config/types';
import analytics from '../Analytics';
import { setClientCache } from '../../actions';

interface ClientCacheObservables {
  onChange$: Observable<InMemoryCacheData>
}

declare global {
  interface Window {
    appziSettings: any;
  }
}

/**
 * This module listens to updates in the Ynomia Client SDK in-memory cache.
 */
class CacheObserver {
  private onChangeSubscription?: Subscription;

  private static clientCacheDispatch?: any;

  /**
   * Use this method after establishing a new `InMemoryCache` instance for `@ynomia/client` to
   * subscribe this observer class to the provided state-change observable.
   * @param {ClientCacheObservables} clientCacheObservables
   * @return {void}
   */
  initialize({ onChange$ }: ClientCacheObservables, clientCacheDispatch?: ContextDispatch): void {
    CacheObserver.clientCacheDispatch = clientCacheDispatch;
    // 1. Unsubscribe from any existing auth observations to avoid memory leaks.
    if (this.onChangeSubscription) {
      this.onChangeSubscription.unsubscribe();
    }
    // 2. Subscribe to the new observables and bind them to their own event handlers.
    this.onChangeSubscription = onChange$.subscribe(CacheObserver.onChange);
  }

  /**
   * @event onChange
   * Emits every time the Client SDK cache is updated.
   * @param {InMemoryCacheData} clientCache
   * @return {void}
   */
  private static onChange(clientCache: InMemoryCacheData): void {
    // @TODO Set the client cache in the state
    if (CacheObserver.clientCacheDispatch !== undefined) {
      window.appziSettings = {
        data: {
          email: clientCache.auth.userInfo?.email,
        },
        render: {
          type: 'client',
        },
      };
      analytics.identify(clientCache);
      setClientCache(clientCache, CacheObserver.clientCacheDispatch);
    }
  }
}

const cacheObserver = new CacheObserver();

export default cacheObserver;
