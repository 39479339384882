import { RawObservationRecord } from '../config/types';

export const getFirstObservationWithType = (
  observations: RawObservationRecord[] | undefined,
  observationType: string,
) => observations?.find(observation => observation.type === observationType);

export const getFirstObservationExists = (
  observations: RawObservationRecord[] | undefined,
  observationType: string,
) => {
  const observation = getFirstObservationWithType(observations, observationType);
  if (!observation) return 'No';
  return 'Yes';
};

export const getFirstObservationDate = (
  observations: RawObservationRecord[] | undefined,
  observationType: string,
) => {
  const observation = getFirstObservationWithType(observations, observationType);
  if (!observation) return '';
  return observation.date;
};

export const getFirstObservationField = (
  observations: RawObservationRecord[] | undefined,
  observationType: string,
  field: string,
) => {
  const observation = getFirstObservationWithType(observations, observationType);
  if (!observation) return '';
  return observation.fields?.[field];
};
