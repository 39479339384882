import React, {
  createContext, FC, useContext, useReducer,
} from 'react';
import { AssetUpdateMode } from '../config/constants';
import AssetUpdateReducer from '../reducers/assetUpdate';
import { ContextDispatch } from '../config/types';

export type AssetUpdateStateType = {
  isPlanningAsset: boolean
  isApplyingAsset: boolean
  plannedUpdatedAsset: any | null
  fileName: string | null
  importTime: Date | null;
  fileContents: string | null
  assetUpdateMode: AssetUpdateMode
  filtersAssetUpdate: Array<string>
  filtersAssetUpdateTable: Array<string>
  showChangesOnly: boolean
  error: {
    status: number
    body: {
      code: string
      msg: string
    }
  } | null
};

const initialState: AssetUpdateStateType = {
  isPlanningAsset: false,
  isApplyingAsset: false,
  plannedUpdatedAsset: null,
  fileName: null,
  importTime: null,
  fileContents: null,
  assetUpdateMode: 'upsert',
  filtersAssetUpdate: [],
  filtersAssetUpdateTable: [],
  showChangesOnly: false,
  error: null,
};

export const AssetUpdateContext = createContext({
  state: initialState,
  dispatch: (() => { }) as ContextDispatch,
});

export const useAssetUpdate = (): [AssetUpdateStateType, ContextDispatch] => {
  const { state, dispatch } = useContext(AssetUpdateContext);
  return [state, dispatch];
};

export const AssetUpdateProvider:FC<React.PropsWithChildren<{}>> = ({ children }) => {
  const [state, dispatch] = useReducer(AssetUpdateReducer, initialState);

  return (
    <AssetUpdateContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        state,
        dispatch,
      }}
    >
      {children}
    </AssetUpdateContext.Provider>
  );
};
