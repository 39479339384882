import { RequestError } from '@ynomia/client';
import { SET_FETCHING_USER, SET_USER } from './actionTypes';
import { ContextDispatch } from '../config/types';
import { fetchUser } from '../services';
import { notification } from '../antdProvider';

export const setUserLoading = (
  status: boolean,
  dispatch: ContextDispatch,
) => dispatch({ type: SET_FETCHING_USER, payload: status });

export const getUser = async (
  userId: string,
  dispatch: ContextDispatch,
) => {
  setUserLoading(true, dispatch);
  try {
    const res = await fetchUser(userId);
    dispatch({
      type: SET_USER,
      payload: res,
    });
  } catch (error) {
    const { status, body } = error as RequestError;
    notification.error({
      message: status,
      description: body?.msg || '',
    });
  }
  setUserLoading(false, dispatch);
};
