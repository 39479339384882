import React, { FC } from 'react';
import { Layout } from 'antd';
import { Announcement, MenuHeader } from '../../connected';
import styles from './styles.module.less';

interface Props {
  children: React.ReactNode | null,
  menuHeaderChildren: JSX.Element | null,
  show?: boolean,
}

const SiteLayout: FC<Props> = ({ children, menuHeaderChildren, show = false }) => {
  if (!show) {
    return (
      <div className={styles.siteLayout}>
        {children}
      </div>
    );
  }

  return (
    <Layout className={styles.siteLayout}>
      <Announcement />
      <MenuHeader>
        {menuHeaderChildren}
      </MenuHeader>
      {children}
    </Layout>
  );
};

export default SiteLayout;
