import React, {
  createContext, FC, useContext, useReducer,
} from 'react';
import { ContextDispatch } from '../config/types';
import FileReducer from '../reducers/file';

export type FileStateType = {
  imgSrc: string | null
  error: {
    message: string
    code: string
  }
};

const initialState: FileStateType = {
  imgSrc: null,
  error: {
    message: '',
    code: '',
  },
};

export const FileContext = createContext({
  state: initialState,
  dispatch: (() => { }) as ContextDispatch,
});

export const useFile = (): [FileStateType, ContextDispatch] => {
  const { state, dispatch } = useContext(FileContext);
  return [state, dispatch];
};

export const FileProvider:FC<React.PropsWithChildren<{}>> = ({ children }) => {
  const [state, dispatch] = useReducer(FileReducer, initialState);

  return (
    <FileContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        state,
        dispatch,
      }}
    >
      {children}
    </FileContext.Provider>
  );
};
