import { Modal, Typography } from 'antd';
import React, {
  FC, useCallback, useMemo, useState,
} from 'react';
import { Field } from '@ynomia/core/dist/blueprint';
import { FormEntry } from '@ynomia/dynamic-form';
import { analytics, updateAsset } from '../../../../../services';
import {
  getAssetTypes,
  getLayerChildrenMap,
  getLayerSegmentedPickerColumns,
  getScratchProjectCode,
  getSelectedAssetType,
  getTenant,
} from '../../../../../selectors';
import ModalForm from '../../../../atoms/ModalForm';
import { getAssets } from '../../../../../actions';
import { getContextStores } from '../../../../../context';
import { layerSegmentedPickerHelper } from '../../../../../utils';

interface Props {
  assetId: string
}

const NOT_SPECIFIED = 'not_specified';

const EditAsset: FC<Props> = ({ assetId }) => {
  /* Context  */
  const contextStores = getContextStores();

  const { assetState, layerState, assetDispatch } = contextStores;
  const { lastFetchStartTime } = assetState;
  const { layersKeyedById } = layerState;
  const { assetsKeyedById } = assetState;

  const tenant = getTenant(contextStores);
  const layerChildrenMap = getLayerChildrenMap(contextStores);
  const layerSegmentedPickerColumns = getLayerSegmentedPickerColumns(contextStores);
  const projectCode = getScratchProjectCode(contextStores);
  const assetTypes = getAssetTypes(contextStores);
  const currentType = getSelectedAssetType(contextStores);

  const existingAsset = assetsKeyedById.get(assetId)!;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
    analytics.trackEvent('Edit Asset Opened', { asset_type: currentType as unknown as string });
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const submitForm = useCallback(async (values: any) => {
    analytics.trackEvent('Edit Asset Submitted', { asset_type: currentType as unknown as string });
    let destinationLayerId: string | undefined = NOT_SPECIFIED;

    setSubmitLoading(true);
    const {
      label,
      type,
      segmentedDestination,
      location,
      ...fields
    } = values;

    if (segmentedDestination) {
      const destinationLayerIds = (segmentedDestination as { [layerType: string]: string });
      destinationLayerId = layerSegmentedPickerHelper.getYoungestLayer(
        layerChildrenMap,
        destinationLayerIds,
      );
    }

    await updateAsset(
      tenant,
      projectCode,
      existingAsset?.id,
      {
        label,
        type,
        destinationLayerId: destinationLayerId !== NOT_SPECIFIED ? destinationLayerId : undefined,
        fields,
      },
    );

    await getAssets(tenant, projectCode, assetTypes, lastFetchStartTime, assetDispatch);
    setSubmitLoading(false);
    setIsModalOpen(false);
  }, [existingAsset]);

  const segmentedDestinationDefaultValue = (
    layerId?: string,
  ): { [type: string]: string } | undefined => {
    if (!layerId) return undefined;
    const layer = layersKeyedById.get(layerId);
    if (!layer) return undefined;
    return {
      [layer.type]: layer.id,
      ...segmentedDestinationDefaultValue(layer.parentID),
    };
  };

  const assetTypesList = assetTypes.map(assetType => ({
    label: assetType.label,
    value: assetType.id,
  }));

  const defaultValues = useMemo(() => ({
    type: existingAsset?.type || assetTypesList[0]?.value,
    segmentedDestination: segmentedDestinationDefaultValue(
      existingAsset?.destination?.layerID,
    ),
    location: existingAsset?.destination?.twinID?.length
      ? existingAsset?.destination.twinID.join(', ')
      : undefined,
    label: existingAsset?.label,
    ...(existingAsset?.fields || {}),
  }), [existingAsset, assetTypes]);

  const formFields = useMemo(() => {
    // Base form fields
    const fields: Array<FormEntry> = [
      {
        entryComponent: 'picklist',
        id: 'type',
        label: 'Asset Type',
        properties: {
          options: assetTypesList,
        },
        isRequired: true,
        disabled: !!existingAsset || !!currentType.id,
      },
      {
        entryComponent: 'text',
        id: 'label',
        label: 'Label',
        properties: {
          placeholder: 'Name, Part ID etc...',
        },
        isRequired: true,
      },
    ];

    if (existingAsset?.destination?.layerID) {
      fields.push(
        {
          entryComponent: 'segmented_picklist',
          id: 'segmentedDestination',
          label: 'Destination',
          properties: {
            columns: layerSegmentedPickerColumns,
            resetValuesOnChange: true,
          },
          isRequired: false,
          disabled: true,
        },
      );
    }

    if (existingAsset?.destination?.twinID?.length) {
      fields.push(
        {
          entryComponent: 'text',
          id: 'location',
          label: 'Model Location Ref.',
          isRequired: false,
          disabled: true,
        },
      );
    }

    const newAssetFormFilter = (field: Field) => {
      if (currentType.visible_fields_on_create) {
        return currentType.visible_fields_on_create.includes(field.id);
      }
      return !field.readonly && !field.hidden;
    };

    const existingAssetFormFilter = (field: Field) => !field.hidden;

    return [
      ...fields,
      ...currentType?.fields
        ?.filter(field => (existingAsset
          ? existingAssetFormFilter(field)
          : newAssetFormFilter(field))).map(field => ({
          entryComponent: field.fieldType,
          id: field.id,
          label: field.label,
          isRequired: field.required,
          properties: field.properties,
          show: field.show,
          disabled: field.disabled || (existingAsset && field.readonly),
          validationConditions: field.validationConditions,
        }))
      || [],
    ];
  }, [existingAsset, currentType, assetTypes]);

  return (
    <>
      <Typography onClick={showModal}>
        Edit
      </Typography>
      <Modal
        title={`Edit Asset ${existingAsset?.label}`}
        open={isModalOpen}
        closable={false}
        footer={[]}
        style={{ padding: 0 }}
        destroyOnClose
      >
        <ModalForm
          fields={formFields}
          onSubmit={submitForm}
          onCancel={handleCancel}
          submitButtonLoading={submitLoading}
          defaultValues={defaultValues}
        />
      </Modal>
    </>
  );
};
export default EditAsset;
