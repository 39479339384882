import { matchPath } from 'react-router-dom';
import { PROJECT_SLUG, Route, routesKeyedById } from '../config/constants';
import client from './Client';

/**
 * Gets a list of all routes that the user has access to.
 */
export const getAllRoutes = () => {
  const menu = client?.cache?.current.bootstrap?.project?.menu;
  if (!menu) return undefined;
  const allRoutes = menu.map((menuItem) => {
    const path = routesKeyedById.get(menuItem.name);
    if (path) {
      return {
        path: path.path,
        name: menuItem.name,
      };
    }
    const custom = client.cache.current.bootstrap?.project?.features?.[menuItem.name]?.customPath;
    return {
      path: custom,
      name: menuItem.name,
    };
  });
  return allRoutes;
};

/**
 * location.pathname only provides the url path, and does not provide the name
 * nor the config url path with dynamic segment declarations.
 * This function provides the route name and the path
 * with the original pattern matching declarations.
 * ie. (`/:projectSlug/` instead of `/tcr-abcdefg/`)
 */
export const getCurrentRoute = () => {
  const currentPath = window.location.pathname;
  const allRoutes = getAllRoutes();
  if (!allRoutes) return undefined;
  return allRoutes.find(r => matchPath(r.path, currentPath));
};

/**
 * Auto decorates the current path with new dynamic segments.
 * @param options values for the dynamic segments.
 * @param pathOverride if not provided, function defaults to the current route.
 */
export const getDecoratedPath = (options?: { projectSlug?: string }, pathOverride?: string) => {
  const { projectSlug } = options ?? {};
  const route = pathOverride ?? getCurrentRoute()?.path;
  return route?.replace(`/${PROJECT_SLUG}/`, `/${projectSlug}/`);
};

export function getRoutePathFromName(name: string, routes: Map<string, Route>) {
  const routePath = routes.get(name)?.path;
  if (routePath) return routePath;
  const path = client.cache.current.bootstrap?.project?.features?.[name]?.customPath;
  if (!path) return undefined;
  return path;
}

/**
 * Fetches the default route by checking the below:
 *  1) First checks if any of the predefined routes are valid
 *  2) Gets the first valid route in features
 *  3) Otherwise, kicks the user to the login screen
 * @param requestedRoute Returns requested route if valid
 * @param features Features from bootstrap
 * @param routes Mapping between the route key and route
 */
export function getDefaultRouteFromProject(
  requestedRoute: string | undefined,
  routes: Map<string, Route>,
) {
  const allRoutes = getAllRoutes();
  if (allRoutes && routes) {
    if (requestedRoute?.startsWith('/files')) {
      return requestedRoute;
    }

    // We test the route keys in a priority order
    // If the predefined keys fail, try the first keys provided by menu
    if (requestedRoute) {
      const requestedRouteUrl = new URL(requestedRoute, window.location.origin);
      const validRouteId = allRoutes.find(
        ({ path }) => matchPath(path, requestedRouteUrl.pathname),
      )?.name;
      if (validRouteId) return requestedRoute;
    }

    const path = allRoutes
      .map(menuItem => getRoutePathFromName(menuItem.name, routes))
      .find(p => p);
    if (path) return path;
    return '/404';
  }

  return '/login';
}

/**
 * Intelligently gets the default route. Will return the requested route if available.
 * @param requestedRoute A route that the user is requesting to access
 */
export function getDefaultRoute(requestedRoute?: string) {
  // Prioritise the redirect route.
  if (requestedRoute?.startsWith('/redirect?')) return requestedRoute;
  const menu = client?.cache?.current.bootstrap?.project?.menu;

  // If user's not logged in, default to the requested path
  // The page will take the user back to login if this fails anyway
  if (!menu && requestedRoute) return requestedRoute;

  return getDefaultRouteFromProject(requestedRoute, routesKeyedById);
}
