import {
  Button,
  Modal,
  Progress,
  Spin,
  Steps,
  Switch,
  Upload,
} from 'antd';
import {
  CloseCircleOutlined,
  InboxOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import { isEmpty, keyBy } from 'lodash';
import { Blueprint } from '@ynomia/core/dist/project/blueprint';
import Dragger from 'antd/es/upload/Dragger';
import { RcFile } from 'antd/es/upload';
import type { UploadProps } from 'antd';
import axios from 'axios';
import dayjs from 'dayjs';
import client from '../../../services/Client';
import { PRESET_TWIN_ID_NAME } from '../../../config/constants';
import ModalForm from '../../atoms/ModalForm';
import { getAssets, getLayers } from '../../../actions';
import {
  getAssetTypes,
  getAssetTypesById,
  getScratchProjectCode,
  getTenant,
} from '../../../selectors';
import ModelEditor, { ApsModel } from '../ModelEditor';
import { fetchBlueprint } from '../../../services/configuration';
import { getContextStores } from '../../../context';
import { modelIsEnabled } from '../../../utils/modelViewing';
import { notification } from '../../../antdProvider';
import styles from './styles.module.less';

type ValueOf<T> = T[keyof T];
type IndividualModel = ValueOf<Blueprint['models']['forge']['settings']['individualModels']>;

enum SetupStepsEnum {
  selectFile = 0,
  upload = 1,
  translation = 2,
  mapping = 3,
  finish = 4,
}

export interface Props {
  apsModels?: Array<ApsModel>,
  modelsKeyedById: Blueprint['models']['forge']['settings']['individualModels'],
  savingInProgress: boolean,
  isSetupModalOpen: boolean,
  objectKeyOverride?: string,
  stepOverride?: number,
  modelOffset: { x: number, y: number, z: number } | undefined,
  updateModel: (objectKey: string, body: IndividualModel | undefined) => Promise<void>,
  updateMap: ((
    objectKey: string,
    mapping: Record<string, Array<number>>,
    twinIdField: string,
  ) => Promise<void>),
  updateOffset: (offset: { x: number, y: number, z: number }) => Promise<void>,
  fetchModels: () => void,
  setIsSetupModalOpen: (open: boolean) => void,
  setModelEnabled: (objectKey: string, enable: boolean) => void,
}

const ModelSetupModal = ({
  apsModels,
  modelsKeyedById,
  savingInProgress,
  isSetupModalOpen,
  objectKeyOverride,
  stepOverride,
  modelOffset,
  updateModel,
  updateMap,
  updateOffset,
  fetchModels,
  setIsSetupModalOpen,
  setModelEnabled,
}: Props) => {
  const contextStores = getContextStores();
  const {
    assetDispatch,
    assetState,
    layerDispatch,
    layerState,
  } = contextStores;
  const [uploadFile, setUploadFile] = useState<RcFile | undefined>();

  // Upload Modal States
  const [uploadObjectKey, setUploadObjectKey] = useState<string | undefined>(objectKeyOverride);
  const [uploadPercent, setUploadPercent] = useState<number>(0);
  const [uploadStep, setUploadStep] = useState<number>(stepOverride || SetupStepsEnum.selectFile);

  /* Selectors */
  const projectCode = getScratchProjectCode(contextStores);
  const tenant = getTenant(contextStores);
  const assetTypes = getAssetTypes(contextStores);
  const assetTypesKeyedById = getAssetTypesById(contextStores);

  const { lastFetchStartTime } = assetState;

  const apsModelsKeyedByObjectKey = keyBy(apsModels, 'objectKey');
  const apsModelManifest = apsModelsKeyedByObjectKey[uploadObjectKey!]?.manifest;
  const translationProgress = apsModelManifest?.progress;
  const translationFailed = apsModelManifest?.status === 'failed';
  const messages = apsModelManifest
    ?.derivatives
    ?.map(d => d.messages)
    ?.flat();

  /**
   * There are two locations in where we associate models with asset types.
   * The first is in the blueprint `blueprint.models.forge.settings.individualModels` which gives
   * us a list of all models enabled for the asset types.
   * The second is under the model `model.assetTypes` which tells us the intended asset type of the
   * model regardless if it  is enabled or not.
   * As the latter is a new field, we want to make sure that when this page is loaded,
   * it is kept up to date with the blueprint list.
   * @param blueprint Blueprint
   */
  const fixModelAssetTypes = async () => {
    const blueprint: Blueprint = await fetchBlueprint(tenant, projectCode);
    const assetTypeModelMapping: { [modelId: string]: Array<string> } = {};
    assetTypes.forEach(({ individualModelIds, type }) => {
      individualModelIds?.forEach((id) => {
        if (!assetTypeModelMapping[id]) assetTypeModelMapping[id] = [];
        assetTypeModelMapping[id].push(type);
      });
    });

    await Promise.all(Object.keys(blueprint.models.forge.settings.individualModels)
      .map((m) => {
        const model = blueprint.models.forge.settings.individualModels[m];
        const modelsArr = model.assetTypes || [];
        const assetTypesArr = assetTypeModelMapping[m] || [];
        const combinedArr = Array.from(new Set([...modelsArr, ...assetTypesArr]));
        if (modelsArr?.sort().join() === combinedArr.sort().join()) return undefined;
        return updateModel(
          m,
          {
            ...model,
            assetTypes: Array.from(combinedArr),
          },
        );
      }));
  };

  useEffect(() => {
    if (!isSetupModalOpen) return;
    setUploadStep(stepOverride || SetupStepsEnum.selectFile);
    setUploadPercent(0);
    setUploadFile(undefined);
    setUploadObjectKey(objectKeyOverride);
  }, [isSetupModalOpen]);

  useEffect(() => {
    if (translationProgress !== 'complete') return;
    if (translationFailed) return;
    if (uploadStep !== SetupStepsEnum.translation) return;
    setUploadStep(SetupStepsEnum.mapping);
  }, [translationProgress]);

  useEffect(() => {
    // Need this to visualise twinID mapping
    getAssets(tenant, projectCode, assetTypes, lastFetchStartTime, assetDispatch);
    getLayers(tenant, projectCode, layerState.lastFetchStartTime, layerDispatch);
  }, []);

  useEffect(() => {
    fetchModels();
    fixModelAssetTypes();
    const t = setInterval(fetchModels, 60 * 1000);
    return () => clearInterval(t);
  }, []);

  const startUploadFile = async () => {
    if (!uploadObjectKey) return;
    const formData = new FormData();
    formData.append('file', uploadFile!);
    const signedUrlResp = await client.server.get(
      `/scratch/models/${tenant}/${projectCode}/signedUrl/${uploadObjectKey}`,
    );
    const { uploadKey, urls } = signedUrlResp.data.value;
    const axiosResp = await axios.put(
      urls[0],
      uploadFile,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: e => setUploadPercent(Math.round((e.loaded / e.total) * 100)),
      },
    );
    if (axiosResp.status !== 200) {
      alert('upload failed, please try again');
      setIsSetupModalOpen(false);
      return;
    }
    const completeSignedUploadResp = await client.server.post(
      `/scratch/models/${tenant}/${projectCode}/completeSignedUpload/${uploadObjectKey}`,
      { uploadKey },
    );
    const { translation } = completeSignedUploadResp.data.value;
    if (translation.result !== 'success') {
      alert('upload failed, please try uploading again');
      setIsSetupModalOpen(false);
      return;
    }
    await updateModel(uploadObjectKey, {
      ...modelsKeyedById[uploadObjectKey],
      urn: translation.urn,
    });
    setUploadStep(SetupStepsEnum.translation);
  };

  useEffect(() => {
    if (uploadStep === SetupStepsEnum.upload) {
      startUploadFile();
    }
  }, [uploadStep]);

  const getExtensionFromFileName = (name: string) => name.split('.').pop() as string;

  const acceptedFiles = ['rvt', 'RVT', 'ifc', 'IFC'];
  const draggerProps: UploadProps = {
    name: 'file',
    multiple: false,
    maxCount: 1,
    accept: acceptedFiles.map(e => `.${e}`).join(','),
    beforeUpload: (file) => {
      const extension = getExtensionFromFileName(file.name);
      if (!acceptedFiles.includes(extension)) {
        notification.error({
          message: 'Error',
          description: 'File must be of .rvt or .ifc format',
        });
        return Upload.LIST_IGNORE;
      }
      setUploadFile(file);
      // Prevent upload
      return false;
    },
    fileList: uploadFile
      ? [{
        uid: '1',
        name: uploadFile.name,
        status: 'done',
        url: '',
      }]
      : [],
    style: {
      marginTop: 30,
    },
    disabled: savingInProgress,
  };

  const onDetailsSubmit = async (e: { [id: string]: any }) => {
    const extension = getExtensionFromFileName(uploadFile!.name);
    const nameAssetTypes = e.assetTypes.map(t => assetTypesKeyedById.get(t)?.name?.toLowerCase());
    const nameContent = [
      ...nameAssetTypes,
      e.building,
      e.softwareVersion,
      e.version,
      dayjs().format('YYYY-MM-DD'),
    ].join('_');
    const name = `${nameContent}.${extension}`;

    if (apsModelsKeyedByObjectKey[name] || modelsKeyedById[name]) {
      notification.error({
        message: 'Error',
        description: `A model with the same version already exists.
        Please provide a unique version number.`,
      });
      return;
    }

    setUploadObjectKey(`${nameContent}.${extension}`);

    await updateModel(
      name,
      {
        urn: '',
        mapping: {},
        assetTypes: e.assetTypes,
        twinIdField: e.twinIdField,
        originalFileName: uploadFile?.name || '',
        date: new Date(),
      },
    );
    setUploadStep(SetupStepsEnum.upload);
  };

  const urlSafe = (id: string) => ({
    condition: `not isSafeUrlParameter(${id} of values)`,
    message: 'This must not contain spaces or special characters',
  });

  const enabled = modelIsEnabled(uploadObjectKey!, modelsKeyedById, assetTypesKeyedById);

  const selectFileStepContent = (
    <>
      <Dragger {...draggerProps}>
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">
          Click or drag a file to this area to upload
        </p>
        <p className="ant-upload-hint">
          &#34;.rvt&#34; and &#34;.ifc&#34; files supported
        </p>
      </Dragger>
      <ModalForm
        fields={[
          {
            disabled: false,
            entryComponent: 'text',
            id: 'building',
            isRequired: true,
            properties: {
              placeholder: `ie. ${projectCode}, building a, podium`,
            },
            validationConditions: [urlSafe('building')],
            label: 'Model Name / Building(s)',
          },
          {
            entryComponent: 'picklist',
            id: 'assetTypes',
            label: 'Asset Type(s)',
            properties: {
              options: assetTypes.map(assetType => ({
                label: assetType.label,
                value: assetType.id,
              })),
              multi: true,
            },
            isRequired: true,
          },
          {
            entryComponent: 'picklist',
            id: 'twinIdField',
            label: 'Twin ID Property Key',
            properties: {
              options: PRESET_TWIN_ID_NAME.map(label => ({
                label,
                value: label,
              })),
              tags: true,
              maxCount: 1,
            },
            isRequired: true,
          },
          {
            label: 'divider',
            id: 'divider',
            entryComponent: 'divider',
          },
          {
            disabled: false,
            entryComponent: 'text',
            id: 'version',
            isRequired: true,
            properties: {
              placeholder: 'ie. V1',
            },
            validationConditions: [urlSafe('version')],
            label: 'Model Version',
          },
          {
            disabled: false,
            entryComponent: 'text',
            id: 'softwareVersion',
            isRequired: true,
            properties: {
              placeholder: 'ie. 2020',
            },
            validationConditions: [urlSafe('softwareVersion')],
            label: 'Revit/Software Version',
          },
        ]}
        submitButtonText="Proceed to upload"
        onSubmit={onDetailsSubmit}
        isDisabled={savingInProgress}
        disableSubmitButton={!uploadFile}
        hideCancelButton
        submitButtonLoading={!!uploadObjectKey}
        extraFiltrexFunctions={
          { isSafeUrlParameter: str => str && encodeURIComponent(str) === str }
        }
      />
    </>
  );

  const uploadStepContent = (
    <>
      Upload in progress...
      {(uploadPercent === 0 || uploadPercent === 100) && (
      <Spin
        indicator={<LoadingOutlined style={{ fontSize: 14 }} spin />}
        style={{ marginLeft: 10 }}
      />
      )}
      <Progress percent={uploadPercent} type="line" />
    </>
  );

  const translationStepContent = (
    <div className={styles.stepContainer}>
      <div className={styles.translationContainer}>
        The model is being translated in the cloud.
        <br />
        <br />
        You may wait until it&#39;s finished or close
        the page and come back later to complete setup.
        <br />
      </div>
      <LoadingOutlined spin />
      <br />
      {translationProgress}
      <br />
      <br />
    </div>
  );

  const translationStepFailedContent = (
    <div className={styles.stepContainer}>
      <div className={styles.translationContainer}>
        The model failed to translate.
        <br />
        <br />
        Please check the logs below and try again.
        <br />
      </div>
      <CloseCircleOutlined style={{ fontSize: 24, color: 'red' }} />
      <br />
      {messages?.map(m => (
        <div key={m?.message}>
          <b>{m?.code || ''}</b>
          <br />
          {m?.message || ''}
        </div>
      ))}
    </div>
  );

  const mappingStepContent = (
    uploadObjectKey && uploadStep === SetupStepsEnum.mapping && isSetupModalOpen
  ) ? (
    <div className={styles.modelEditorContainer}>
      <ModelEditor
        objectKey={uploadObjectKey}
        modelsKeyedById={modelsKeyedById}
        apsModel={apsModelsKeyedByObjectKey[uploadObjectKey]}
        updateMap={updateMap}
        updateModel={updateModel}
        disableUpdate={savingInProgress}
        updateOffset={updateOffset}
        modelOffset={modelOffset}
      />
      <Button
        type="primary"
        style={{ width: 100 }}
        disabled={isEmpty(modelsKeyedById[uploadObjectKey]?.mapping)}
        onClick={() => setUploadStep(SetupStepsEnum.finish)}
      >
        Next
      </Button>
    </div>
    ) : null;

  const finishStepContent = (
    <div className={styles.finishContentContainer}>
      Setup Complete!
      <br />
      Enable the model to publish it.
      <br />
      <br />
      <Switch
        disabled={savingInProgress}
        checked={enabled}
        onClick={() => setModelEnabled(uploadObjectKey!, !enabled)}
        loading={savingInProgress}
      />
      <br />
      Remember to delete or disable any models you do not want to be visible to users.
      <br />
      <br />
      <Button
        type="primary"
        style={{ width: 100 }}
        onClick={() => setIsSetupModalOpen(false)}
      >
        Close
      </Button>
    </div>
  );

  const items: Array<{
    key: number,
    title: string,
    content: React.ReactNode
  }> = [
    {
      key: SetupStepsEnum.selectFile,
      title: 'Select File',
      content: selectFileStepContent,
    },
    {
      key: SetupStepsEnum.upload,
      title: 'Upload',
      content: uploadStepContent,
    },
    {
      key: SetupStepsEnum.translation,
      title: 'Translation',
      content: translationFailed ? translationStepFailedContent : translationStepContent,
    },
    {
      key: SetupStepsEnum.mapping,
      title: 'Mapping',
      content: mappingStepContent,
    },
    {
      key: SetupStepsEnum.finish,
      title: 'Finish',
      content: finishStepContent,
    },
  ];

  return (
    <Modal
      title="Add Model"
      open={isSetupModalOpen}
      width={1000}
      footer={null}
      onCancel={() => setIsSetupModalOpen(false)}
      closable={uploadStep !== SetupStepsEnum.upload}
      maskClosable={false}
    >
      <div style={{ padding: 30 }}>
        <Steps current={uploadStep} items={items} style={{ width: 800 }} />
        <div style={{ marginTop: 30 }}>{items[uploadStep].content}</div>
      </div>
    </Modal>
  );
};

export default ModelSetupModal;
