import {
  PLAN_UPDATE_STATUSES,
  RESET_IMPORT_STATUS_UPDATE,
  SET_IS_APPLYING_UPDATE_STATUSES,
  SET_IS_PLANNING_UPDATE_STATUSES,
  SET_STATUSES_ERROR,
  SET_STATUSES_SHOW_CHANGES_ONLY,
  SET_STATUSES_TABLE_FILTERS,
  SET_STATUSES_UPDATE_MODE,
  SET_STATUS_UPDATE_FILE,
  SET_STATUS_UPDATE_FILTERS,
} from '../actions/actionTypes';
import { ContextDispatchParam } from '../config/types';
import { StatusUpdateStateType } from '../context';
import { getFilterCsv } from '../utils';

export default (
  state: StatusUpdateStateType,
  action: ContextDispatchParam,
): StatusUpdateStateType => {
  switch (action.type) {
    case SET_STATUS_UPDATE_FILE:
      return {
        ...state,
        fileName: action.payload.name,
        fileContents: action.payload.contents,
      };
    case SET_IS_PLANNING_UPDATE_STATUSES:
      return {
        ...state,
        isPlanningStatuses: action.payload,
      };
    case PLAN_UPDATE_STATUSES: {
      const fields = getFilterCsv(action.payload);
      return {
        ...state,
        importTime: new Date(),
        plannedUpdatedStatus: action.payload,
        filtersStatusUpdate: fields,
        filtersStatusUpdateTable: fields,
      };
    }
    case SET_IS_APPLYING_UPDATE_STATUSES:
      return {
        ...state,
        isApplyingStatuses: action.payload,
      };
    case SET_STATUSES_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case RESET_IMPORT_STATUS_UPDATE:
      return {
        ...state,
        isPlanningStatuses: false,
        isApplyingStatuses: false,
        plannedUpdatedStatus: null,
        fileName: null,
        fileContents: null,
      };
    case SET_STATUS_UPDATE_FILTERS:
      return {
        ...state,
        filtersStatusUpdate: action.payload,
      };
    case SET_STATUSES_UPDATE_MODE:
      return {
        ...state,
        statusUpdateMode: action.payload,
      };
    case SET_STATUSES_TABLE_FILTERS:
      return {
        ...state,
        filtersStatusUpdateTable: action.payload,
      };
    case SET_STATUSES_SHOW_CHANGES_ONLY:
      return {
        ...state,
        showChangesOnly: action.payload,
      };
    default:
      return state;
  }
};
