import { Device } from '@ynomia/core/dist/device';
import { createSelector } from 'reselect';
import map from './map';

/**
 * Returns devices from the keyed state collection as a non-keyed array.
 */
export const getDevicesArray = createSelector(
  [map.devicesKeyedById],
  (devicesKeyedById) => {
    const keys = [...devicesKeyedById.keys()];
    return keys.map(id => devicesKeyedById.get(id)) as Array<Device>;
  },
);

/**
 * Returns all devices linked to the current project keyed by their "Asset ID" (if known).
 */
export const getLinkedDevicesKeyedByAssetId = createSelector(
  [
    getDevicesArray,
    map.scratchProjectCode,
    map.tenant,
  ],
  (devices, project, tenant) => {
    const devicesAssignedToProject = devices.filter(
      device => device.project === project && device.tenant === tenant,
    );
    return new Map(devicesAssignedToProject.map(device => [device.assetID, device]));
  },
);
