import { createSelector } from 'reselect';
import { getAssetManagerFilters, getAssetTypes, getLayersKeyedByAncestorId } from '..';
import { getAssetsKeyedByStatus, getSelectedLayerAncestors } from '../../utils';
import { getFilteredAssets, getFilteredAssetsContext } from '.';

const getFilteredAssetsForLayerSummary = createSelector(
  [
    getAssetManagerFilters,
    getFilteredAssetsContext,
  ],
  (
    assetManagerFilters,
    filteredAssetsContext,
  ) => {
    const {
      searchTags,
      searchText,
      dateFilter,
      typeFilter,
      assetStatusFilter,
      observationTypeFilter,
      tableFilter,
      supplierFilter,
      multiSearchModeFilter,
    } = assetManagerFilters;
    if (
      !searchTags
      && !dateFilter?.length
      && !typeFilter
      && !assetStatusFilter?.ids?.length
      && !observationTypeFilter?.size
    ) {
      return filteredAssetsContext.assetsArray;
    }

    return getFilteredAssets(filteredAssetsContext, {
      searchTags,
      searchText,
      dateFilter,
      typeFilter,
      assetStatusFilter,
      observationTypeFilter,
      tableFilter,
      supplierFilter,
      multiSearchModeFilter,
    });
  },
);

export const getAssetsKeyedByStatusForLayerSummary = createSelector(
  [getAssetTypes, getFilteredAssetsForLayerSummary],
  getAssetsKeyedByStatus,
);

export const getSelectedLayerAncestorsForLayerSummary = createSelector(
  [
    getLayersKeyedByAncestorId,
    getAssetManagerFilters,
  ],
  (
    layersKeyedByAncestorId,
    assetManagerFilters,
  ) => {
    const {
      selectedLayerIds,
    } = assetManagerFilters;
    return getSelectedLayerAncestors(selectedLayerIds, layersKeyedByAncestorId);
  },
);
