import { Auth0ContextInterface, User } from '@auth0/auth0-react';
import { Observable, Subscription } from 'rxjs';
import { InMemoryCacheData } from '@ynomia/client';
import {
  ACCESS_TOKEN_STORAGE_KEY,
  ID_TOKEN_STORAGE_KEY,
  REFRESH_TOKEN_STORAGE_KEY,
} from '../../config/constants';
import { analytics } from '..';
import auth0Cache from './Auth0Cache';
import client from '.';
import config from '../../config';

interface AuthObservables {
  onEstablish$: Observable<InMemoryCacheData>
  onRefresh$: Observable<InMemoryCacheData>
  onRefreshFailure$: Observable<boolean>
  onTerminate$: Observable<InMemoryCacheData>
}

/**
 * This module listens for changes in the authentication status of the current user.
 */
class AuthService {
  private onEstablishSubscription?: Subscription;

  private onRefreshSubscription?: Subscription;

  private onRefreshFailureSubscription?: Subscription;

  private onTerminateSubscription?: Subscription;

  private static auth0: Auth0ContextInterface<User> | undefined;

  /**
   * Use this method after establishing a new `SessionManager` instance for `@ynomia/client` to
   * subscribe this observer class to the Auth Observables provided.
   * @param {AuthObservables} authObservables
   * @return {void}
   */
  initialize(
    {
      onEstablish$, onRefresh$, onTerminate$, onRefreshFailure$,
    }: AuthObservables,
    auth0: Auth0ContextInterface<User>,
  ): void {
    client.cache.update({ auth: { domain: config.host.api } });

    if (onEstablish$) {
      this.onEstablishSubscription?.unsubscribe();
      this.onEstablishSubscription = onEstablish$.subscribe(AuthService.onEstablish);
    }

    if (onRefresh$) {
      this.onRefreshSubscription?.unsubscribe();
      this.onRefreshSubscription = onRefresh$.subscribe(AuthService.onRefresh);
    }

    if (onRefreshFailure$) {
      this.onRefreshFailureSubscription?.unsubscribe();
      this.onRefreshFailureSubscription = onRefreshFailure$.subscribe(AuthService.onRefreshFailure);
    }

    if (onTerminate$) {
      this.onTerminateSubscription?.unsubscribe();
      this.onTerminateSubscription = onTerminate$.subscribe(AuthService.onTerminate);
    }

    if (auth0) {
      AuthService.auth0 = auth0;
    }
  }

  /**
   * @event onEstablish
   * Emits when a brand new user session is established. This happens typically after a user has
   * just signed in. Note that this event is NOT emitted when restoring existing sessions that
   * have been initialized from local storage data.
   * @param {InMemoryCacheData} clientCache
   * @return {void}
   */
  private static onEstablish(clientCache: InMemoryCacheData): void {
    AuthService.persistAuthDataOffline(clientCache);
  }

  /**
   * @event onRefresh
   * Emits each time a new access token is attained using the user's refresh token.
   * @param {InMemoryCacheData} clientCache
   * @return {void}
   */
  private static onRefresh(clientCache: InMemoryCacheData): void {
    AuthService.persistAuthDataOffline(clientCache);
  }

  /**
   * @event onRefreshFailure
   * Emits when a token refresh fails. At this point, a logout event could be imminent, so this
   * is our last chance to do something before this happens.
   * @return {void}
   */
  private static onRefreshFailure(): void {
    // Event tracking
    analytics.trackEvent('JWT Refresh Failure');
    // The refresh token might be invalid because it's already been refreshed in another browser
    // window or tab. Let's try and hydrate this state from the local storage to see if that makes a
    // difference in the next network request which goes out. If, despite this attempt, the next
    // request still fails - a final logout event will be fired by the Session Manager.
    client.cache.update({
      auth: {
        accessToken: localStorage.getItem(ACCESS_TOKEN_STORAGE_KEY)!,
        refreshToken: localStorage.getItem(REFRESH_TOKEN_STORAGE_KEY)!,
        idToken: localStorage.getItem(ID_TOKEN_STORAGE_KEY)!,
      },
    });
  }

  /**
   * @event onTerminate
   * Emits when a previously authenticated user session has been terminated. This can occur for
   * a number of reasons, including token expiry (with no successful refresh) and the session
   * being manually destroyed (e.g. when signing out).
   * @param {InMemoryCacheData} clientCache
   * @return {void}
   */
  private static onTerminate(clientCache: InMemoryCacheData): void {
    // Event tracking
    analytics.trackEvent('User Logout', { reason: clientCache.auth.unauthenticatedReason });

    localStorage.removeItem(ACCESS_TOKEN_STORAGE_KEY);
    localStorage.removeItem(ID_TOKEN_STORAGE_KEY);
    localStorage.removeItem(REFRESH_TOKEN_STORAGE_KEY);

    const { unauthenticatedReason } = clientCache.auth;
    AuthService.auth0?.logout({
      logoutParams: {
        returnTo: `${window.location.origin}/login?unauthenticatedReason=${unauthenticatedReason}`,
      },
    });
  }

  /**
   * Saves mission critical authentication data offline so that sessions can be restored after
   * refreshing the browser.
   * @param {InMemoryCacheData} clientCache
   * @return {void}
   */
  private static persistAuthDataOffline(clientCache: InMemoryCacheData): void {
    AuthService.saveOffline(ACCESS_TOKEN_STORAGE_KEY, clientCache.auth.accessToken);
    AuthService.saveOffline(ID_TOKEN_STORAGE_KEY, clientCache.auth.idToken);
    AuthService.saveOffline(REFRESH_TOKEN_STORAGE_KEY, clientCache.auth.refreshToken);
  }

  /**
   * Saves data offline into local storage, but only if the value exists.
   * @param {string} key
   * @param {string?} value
   * @return {void}
   */
  private static saveOffline(key: string, value?: string): void {
    if (value) {
      localStorage.setItem(key, value);
    }
  }

  /**
   * Opens Auth0 in an in-app-browser and prompts users to sign in. When successful, a
   * bootstrap request is also made to the Ynomia backend to refresh the latest project data.
   * @return {Promise<void>}
   */
  // eslint-disable-next-line class-methods-use-this
  async login(): Promise<void> {
    await client.session.authenticate({
      getTokens: async () => {
        const { body } = auth0Cache.data || {};
        return {
          accessToken: body?.access_token || '',
          idToken: body?.id_token || '',
          refreshToken: body?.refresh_token || '',
        };
      },
    });
  }
}

const authService = new AuthService();

export default authService;
