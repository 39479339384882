import client from './Client';

export async function fetchLayers(
  tenant: string,
  projectCode: string,
  modifiedAfter?: Date | null,
) {
  const result = await client.server.get(
    `/scratch/project/${tenant}/${projectCode}/layers`,
    {
      params: {
        modifiedAfter: modifiedAfter
          ? new Date(modifiedAfter).toISOString()
          : undefined,
      },
    },
  );
  return result.data.value;
}
