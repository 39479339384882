import { createSelector } from 'reselect';
import { AssetFilter, AssetSelection } from '../context';
import {
  DEFAULT_ASSET_PAGE_FEATURES,
  IS_MOBILE,
  STATUS_TRACKING_PAGE_FEATURES,
} from '../config/constants';
import map from './map';

export const getPageFeatures = createSelector(
  [map.assetManagerId],
  assetManagerId => (assetManagerId === 'status_tracking'
    ? STATUS_TRACKING_PAGE_FEATURES : DEFAULT_ASSET_PAGE_FEATURES),
);

export const getSummaryFeatures = createSelector(
  [getPageFeatures],
  (pageFeatures) => {
    const {
      supplierSummary,
      observationSummary,
      assetStatusSummary,
      layerSummary,
    } = pageFeatures;

    return [
      'digitalTwin',
      'recentStatusChanges',
      supplierSummary ? 'supplierSummary' : false,
      observationSummary ? 'observationSummary' : false,
      assetStatusSummary ? 'assetStatusSummary' : false,
      layerSummary ? 'layerSummary' : false,
    ].filter(summary => summary !== false) as Array<string>;
  },
);

export const getAssetManagerFilters = createSelector([
  map.assetManagerId,
  map.assetManagerKeyedById,
], (assetManagerId, assetManagerKeyedById): AssetFilter => {
  if (!assetManagerKeyedById || !assetManagerKeyedById[assetManagerId]?.filters) {
    return {
      searchTags: [],
      searchText: '',
      dateFilter: [],
      typeFilter: '',
      selectedLayerIds: [],
      assetStatusFilter: {
        toggle: null,
        ids: [],
      },
      observationTypeFilter: {},
      multiSearchModeFilter: 'or',
      openedLayerId: null,
      tableFilter: {},
      pagination: {
        current: 1,
        pageSize: IS_MOBILE ? 10 : 20,
      },
      supplierFilter: [],
      tableSorter: null,
      timeTravelDate: null,
    };
  }

  return assetManagerKeyedById[assetManagerId].filters;
});

export const getAssetManagerCollapsable = createSelector([
  map.assetManagerId,
  map.assetManagerKeyedById,
], (assetManagerId, assetManagerKeyedById) => {
  if (!assetManagerKeyedById || !assetManagerKeyedById[assetManagerId]?.collapsable) {
    return undefined;
  }
  return assetManagerKeyedById[assetManagerId].collapsable;
});

export const getAssetManagerAssetSelection = createSelector([
  map.assetManagerId,
  map.assetManagerKeyedById,
], (assetManagerId, assetManagerKeyedById): AssetSelection => {
  if (!assetManagerKeyedById || !assetManagerKeyedById[assetManagerId]?.assetSelection) {
    return {
      showSelectedOnly: false,
      lastManualUpdate: undefined,
      selectedAssets: [],
    };
  }

  return assetManagerKeyedById[assetManagerId].assetSelection;
});
