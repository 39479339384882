import React, { FunctionComponent } from 'react';
import { Helmet } from 'react-helmet';
import { Error } from '../../components/atoms';

const Error500: FunctionComponent = () => (
  <>
    <Helmet title="500" />
    <Error code="500" />
  </>
);

export default Error500;
