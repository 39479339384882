import React, {
  createContext, FC, useContext, useReducer,
} from 'react';
import { ContextDispatch, VisibilityFilter } from '../config/types';
import visibilityFilterReducer from '../reducers/visibilityFilters';

export type VisibilityFilterStateType = {
  visibilityFiltersKeyedById: Map<string, VisibilityFilter>
  isFetchingVisibilityFilters: boolean
  isCreatingNewVisibilityFilter: boolean
  isUpdatingVisibilityFilter: boolean
};

const initialState: VisibilityFilterStateType = {
  visibilityFiltersKeyedById: new Map(),
  isCreatingNewVisibilityFilter: false,
  isFetchingVisibilityFilters: false,
  isUpdatingVisibilityFilter: false,
};

export const VisibilityFilterContext = createContext({
  state: initialState,
  dispatch: (() => { }) as ContextDispatch,
});

export const useVisibilityFilter = (): [VisibilityFilterStateType, ContextDispatch] => {
  const { state, dispatch } = useContext(VisibilityFilterContext);
  return [state, dispatch];
};

export const VisibilityFilterProvider:FC<React.PropsWithChildren<{}>> = ({ children }) => {
  const [state, dispatch] = useReducer(visibilityFilterReducer, initialState);

  return (
    <VisibilityFilterContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        state,
        dispatch,
      }}
    >
      {children}
    </VisibilityFilterContext.Provider>
  );
};
