import React, { FunctionComponent } from 'react';
import { Helmet } from 'react-helmet';
import { Error } from '../../components/atoms';

const Error404: FunctionComponent = () => (
  <>
    <Helmet title="404" />
    <Error code="404" />
  </>
);

export default Error404;
