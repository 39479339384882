import { createSelector } from 'reselect';
import {
  faCubes,
} from '@fortawesome/free-solid-svg-icons';
import {
  Route, categories, routeIcons, routesKeyedById,
} from '../config/constants';
import map from './map';

/**
 * Returns layers from the keyed state collection as a non-keyed array.
 */
export const getMenuFromBootstrap = createSelector(
  [map.menu, map.features],
  (menu, features) => {
    if (!menu || !features) return [];

    return categories.map((category) => {
      const routes = Object.keys(features)
        ?.filter(key => features[key]?.menu
            && features[key].category === category.id
            && features[key].enabled)
        ?.map((key) => {
          const {
            custom, customIcon, customPath, customLabel,
          } = features[key] || {};
          if (!custom) return routesKeyedById.get(key);
          return {
            id: key,
            path: `${customPath}`,
            label: customLabel,
            icon: routeIcons[customIcon] || faCubes,
          };
        })
        .filter(item => item !== undefined) as Array<Route>;
      return {
        category: category.id,
        name: category.label,
        menu: routes,
      };
    }).filter(item => item.menu.length > 0);
  },
);

export const getMenuItemsFromBootstrap = createSelector(
  [getMenuFromBootstrap],
  menu => menu
    .map(category => category.menu)
    .flat(),
);
