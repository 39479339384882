import { Button, Modal } from 'antd';
import React, { FC } from 'react';
import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import {
  convertLocalizedTimeToProjectTime,
  getProjectTimezone,
  sameDayAsProjectDay,
} from '../../../utils';
import {
  getAssetManagerAssetSelection,
  getScratchProjectCode,
  getSelectedAssetType,
  getSelectedAssetTypeStatuses,
  getTenant,
} from '../../../selectors';
import ModalForm from '../../atoms/ModalForm';
import { getContextStores } from '../../../context';
import styles from './styles.module.less';
import { updateBulkObservation } from '../../../actions';

dayjs.extend(isToday);

interface Props {
  showModal: (open: boolean) => void,
  isModalOpened: boolean,
  disabled: boolean,
}

const UpdateBulkStatus: FC<Props> = ({ showModal, isModalOpened, disabled }) => {
  /* Context  */
  const contextStores = getContextStores();
  const { assetState, assetDispatch } = contextStores;
  const { lastFetchStartTime, isUpdatingBulkObservation } = assetState;

  /* Selectors */
  const projectCode = getScratchProjectCode(contextStores);
  const tenant = getTenant(contextStores);
  const statuses = getSelectedAssetTypeStatuses(contextStores);
  const selectedAssetType = getSelectedAssetType(contextStores);
  const { selectedAssets } = getAssetManagerAssetSelection(contextStores);
  const projTimezone = getProjectTimezone();

  const submitForm = async (values: any) => {
    let isBackdated = false;
    let date: Date = new Date(values.date);
    if (sameDayAsProjectDay(date)) {
      // The current day and time should be used as the status update timestamp
      // The time can sometimes be midnight when the date picker is used, so
      // this makes sure we don't send that time instead of the current time
      date = new Date();
    } else {
      // A different day has been requested.
      // We therefore must convert the selected day into the project's timezone
      date = convertLocalizedTimeToProjectTime(date);
      isBackdated = true;
    }

    await updateBulkObservation(
      tenant,
      projectCode,
      selectedAssets,
      values.status,
      date,
      isBackdated,
      [selectedAssetType],
      lastFetchStartTime,
      assetDispatch,
    );

    showModal(false);
  };

  const statusesOptions = statuses.map((status) => {
    const { id, label, order } = status;
    return {
      label,
      value: id,
      statusDefault: status.default,
      order,
    };
  }).sort((a, b) => (a?.order || 0) - (b?.order || 0));

  const getStatusDefault = () => statusesOptions.find(({ statusDefault }) => statusDefault);

  const onCancel = () => showModal(false);

  const defaultValues = {
    status: getStatusDefault()?.value,
    date: dayjs().tz(projTimezone),
  };

  return (
    <div>
      <Button
        className={styles.buttonContainer}
        onClick={() => showModal(!isModalOpened)}
        disabled={disabled}
        type="primary"
      >
        Update Status
      </Button>
      <Modal
        title="Update Status"
        className="addNewHistory"
        open={isModalOpened}
        closable={false}
        width={342}
        destroyOnClose
        footer={null}
      >
        <ModalForm
          submitButtonLoading={isUpdatingBulkObservation}
          fields={[
            {
              entryComponent: 'picklist',
              id: 'status',
              label: 'Status',
              properties: {
                options: statusesOptions,
              },
              isRequired: true,
              disabled: false,
            },
            {
              disabled: false,
              entryComponent: 'datetime',
              id: 'date',
              isRequired: true,
              label: 'Date',
              properties: {
                restriction: 'past',
                timezone: projTimezone,
                format: 'MMM DD, YYYY [at] h:mma',
                allowClear: false,
              },
              show: undefined,
              validationConditions: undefined,
            },
          ]}
          onCancel={onCancel}
          onSubmit={submitForm}
          defaultValues={defaultValues}
        />
      </Modal>
    </div>
  );
};

export default UpdateBulkStatus;
