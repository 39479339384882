import React, { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useSearchParams } from 'react-router-dom';
import { getUrlRegion, localStorageRegion } from '../../config';
import {
  LOGIN_HINT, PROJECT_ID_STORAGE_KEY, REDIRECT_TO,
} from '../../config/constants';

/**
 * Redirects the user to the next project.
 * The route will be accepted/rejected by the next page.
 */
const RedirectView: React.FC = () => {
  const [searchParams] = useSearchParams();
  const { loginWithRedirect } = useAuth0();
  let redirected = false;

  const handleRegionRedirect = async (requestedRoute?: string) => {
    const loginHint = localStorage.getItem(LOGIN_HINT) || undefined;
    // Do not persist login data
    localStorage.removeItem(LOGIN_HINT);
    await loginWithRedirect({
      authorizationParams: {
        redirectUri: `${window.location.origin}/auth0-callback`,
        login_hint: loginHint,
      },
      appState: {
        [REDIRECT_TO]: requestedRoute || '',
      },
    });
  };

  useEffect(() => {
    // Prevent react strict mode from running the following twice
    if (redirected) return;
    redirected = true;

    const requestedProjectId = searchParams.get(PROJECT_ID_STORAGE_KEY);
    const requestedRoute = searchParams.get(REDIRECT_TO) || undefined;

    const urlRegion = getUrlRegion();
    if (urlRegion && urlRegion !== localStorageRegion) {
      handleRegionRedirect(requestedRoute);
      return;
    }

    searchParams.delete(PROJECT_ID_STORAGE_KEY);
    const searchParamsStr = searchParams.toString();
    // Use native redirect instead of useNavigate to also refresh the page
    window.location.href = `/${requestedProjectId}/${searchParamsStr ? `?${searchParamsStr}` : ''}`;
  }, []);

  return null;
};

export default RedirectView;
