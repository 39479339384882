import React, {
  createContext, FC, useContext, useReducer,
} from 'react';
import { Asset, ContextDispatch } from '../config/types';
import AssetReducer from '../reducers/assets';

export type AssetStateType = {
  assetsKeyedById: Map<string, Asset>
  isFetchingAssets: boolean
  isUpdatingObservation: boolean
  lastFetchFailed: boolean
  lastFetchStartTime: Date | null
  isUpdatingBulkObservation: boolean
  initialAssetFetchComplete: boolean
};

const initialState: AssetStateType = {
  assetsKeyedById: new Map(),
  isFetchingAssets: false,
  lastFetchFailed: false,
  initialAssetFetchComplete: false,
  lastFetchStartTime: null,
  isUpdatingObservation: false,
  isUpdatingBulkObservation: false,
};

export const AssetContext = createContext({
  state: initialState,
  dispatch: (() => { }) as ContextDispatch,
});

export const useAsset = (): [AssetStateType, ContextDispatch] => {
  const { state, dispatch } = useContext(AssetContext);
  return [state, dispatch];
};

export const AssetProvider:FC<React.PropsWithChildren<{}>> = ({ children }) => {
  const [state, dispatch] = useReducer(AssetReducer, initialState);

  return (
    <AssetContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        state,
        dispatch,
      }}
    >
      {children}
    </AssetContext.Provider>
  );
};
