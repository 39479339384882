import { createSelector } from 'reselect';
import { VisibilityFilter } from '../config/types';
import map from './map';

/**
 * VisibilityFilters are stored as a collection keyed by their ID.
 * This method returns these as a list.
 */
export const getVisibilityFiltersArray = createSelector(
  [map.visibilityFiltersKeyedById],
  (visibilityFiltersKeyedById) => {
    if (!visibilityFiltersKeyedById) return [];

    return [...visibilityFiltersKeyedById.values()] as Array<VisibilityFilter>;
  },
);
