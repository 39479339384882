import { Bootstrap } from '@ynomia/core/dist/blueprint';
import { InMemoryCacheData } from '@ynomia/client';
import { currentRegion } from '../config';

declare global {
  interface Window {
    analytics: any;
  }
}

class Analytics {
  private userIdentified: boolean = false;

  private metadata = {};

  setMetadata(bootstrap?: Bootstrap) {
    if (!bootstrap) return;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { metadata, user_roles } = bootstrap?.project || {};
    const { tenant, name, region } = metadata || {};

    this.metadata = {
      environment: currentRegion,
      region,
      tenant,
      project: name,
      role: (user_roles as Array<string>)?.[0],
    };
  }

  /**
   * We only identify the user per session, so this function will not always run on refresh.
   * @param clientCache
   */
  identify(clientCache: InMemoryCacheData) {
    const { userInfo } = clientCache.auth;
    const { email } = userInfo || {};
    this.setMetadata(clientCache.bootstrap);

    if (email && !this.userIdentified) {
      window.analytics.identify(
        userInfo?.auth0Id,
        {
          name: userInfo?.name,
          email: userInfo?.email,
        },
      );

      this.userIdentified = true;
    }
  }

  trackEvent(msg: string, props: Record<string, string | undefined> = {}) {
    window.analytics.track(msg, { ...props, ...this.metadata });
  }

  page(page: string) {
    window.analytics.page(page, this.metadata);
  }
}

const analytics = new Analytics();

export default analytics;
