import React, { FunctionComponent } from 'react';
import { Layout, Typography } from 'antd';
import { useRouteError } from 'react-router-dom';
import styles from './styles.module.less';

const ErrorBoundary: FunctionComponent = () => {
  const { Title } = Typography;
  const error = useRouteError() as Error;

  return (
    <Layout>
      <div className={styles.siteLayout}>
        <div className={styles.container}>
          <div>
            <Title>Uh oh, something went terribly wrong!</Title>
            <Title level={5}>{error.message || JSON.stringify(error)}</Title>
            <div
              onClick={() => { window.location.href = '/'; }}
              role="button"
              tabIndex={0}
            >
              Go Home
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ErrorBoundary;
