import {
  Alert, Button, Card, Collapse, Progress, Typography,
} from 'antd';
import {
  CheckCircleOutlined, EyeOutlined, WarningOutlined,
} from '@ant-design/icons';
import React, { useEffect, useMemo, useState } from 'react';
import { Blueprint } from '@ynomia/core/dist/project/blueprint';
import { FormValues } from '@ynomia/dynamic-form';
import TextArea from 'antd/es/input/TextArea';
import { isEmpty } from 'lodash';
import { JsonEditor, ModelViewer } from '../../atoms';
import {
  getAssetTypes,
  getAssetTypesById,
  getColorScheme,
  getFilteredTwinData,
  getScratchProjectCode,
  getTenant,
} from '../../../selectors';
import {
  setIsLoadingPercentage,
  setIsTwinLoading,
  setIsTwinReady,
} from '../../../actions';
import AssetTypeDropdown from '../AssetTypeDropdown';
import ModalForm from '../../atoms/ModalForm';
import { PRESET_TWIN_ID_NAME } from '../../../config/constants';
import config from '../../../config';
import { getContextStores } from '../../../context';
import { modelIsEnabled } from '../../../utils/modelViewing';
import styles from './styles.module.less';

export type ApsModel = {
  bucketKey: string,
  location: string,
  objectId: string,
  objectKey: string,
  sha1: string,
  size: number
  manifest: {
    type: 'manifest',
    hasThumbnail: string,
    status: string,
    progress: string,
    region: string,
    urn: string,
    version: string,
    derivatives: Array<any>
  }
};

const DEFAULT_MAPPING_FILTER = JSON.stringify([{
  key: "ie. 'Type Name'",
  value: "ie. 'B00' or regex 'B{1}\\d{2}'",
}], null, 2);

type ValueOf<T> = T[keyof T];
type IndividualModels = Blueprint['models']['forge']['settings']['individualModels'];
type IndividualModel = ValueOf<IndividualModels>;

export interface Props {
  objectKey: string,
  modelsKeyedById: IndividualModels,
  apsModel: ApsModel,
  disableUpdate: boolean,
  modelOffset: { x: number, y: number, z: number } | undefined,
  updateMap: ((
    objectKey: string,
    mapping: Record<string, Array<number>>,
    twinIdField: string
  ) => Promise<void>)
  updateOffset: ((offset: { x: number, y: number, z: number }) => Promise<void>)
  updateModel: (objectKey: string, model: IndividualModel) => Promise<void>
}

const ModelEditor = ({
  objectKey,
  modelsKeyedById,
  apsModel,
  disableUpdate,
  modelOffset = { x: 0, y: 0, z: 0 },
  updateMap,
  updateOffset,
  updateModel,
}: Props) => {
  /* Context */
  const contextStores = getContextStores();

  /* Selectors */
  const twinData = getFilteredTwinData(contextStores);
  const colorScheme = getColorScheme(contextStores);
  const project = getScratchProjectCode(contextStores);
  const tenant = getTenant(contextStores);
  const assetTypesKeyedById = getAssetTypesById(contextStores);

  const assetTypes = getAssetTypes(contextStores);
  const { layoutState, digitalTwinDispatch, digitalTwinState } = contextStores;
  const { isTwinLoading } = digitalTwinState;
  const { assetDetailsAssetId } = layoutState;

  const [savingProperties, setSavingProperties] = useState<boolean>(false);
  const [requestMapping, setRequestMapping] = useState<string>();
  const [savingMap, setSavingMapping] = useState<boolean>(false);
  const [showTwin, setShowTwin] = useState<boolean>(true);
  const [twinIDProgress, setTwinIdProgress] = useState<number>();
  const [
    tempTwinIdMapping,
    setTempTwinIdMapping,
  ] = useState<{ [twinID: string]: Array<number> } | undefined>(undefined);
  const [
    modelMappingOverride,
    setModelMappingOverride,
  ] = useState<{ [twinID: string]: Array<number> } | undefined>(undefined);
  const [offset, setOffset] = useState<{ x: number, y: number, z: number } | undefined>(undefined);
  const [mappingFilters, setMappingFilters] = useState<string>(DEFAULT_MAPPING_FILTER);
  const mappingFiltersEdited = mappingFilters !== DEFAULT_MAPPING_FILTER;
  const validMappingFilters = () => {
    if (!mappingFiltersEdited) return true;
    try {
      JSON.stringify(JSON.parse(mappingFilters), null, 2);
      return true;
    } catch (e) {
      return false;
    }
  };
  const getFormattedMappingFilters = () => {
    try {
      return JSON.stringify(JSON.parse(mappingFilters), null, 2);
    } catch (e) {
      return mappingFilters;
    }
  };
  const isMappingFiltersValid = () => {
    try {
      JSON.parse(mappingFilters);
      return true;
    } catch (e) {
      return false;
    }
  };
  const formattedMappingFilters = getFormattedMappingFilters();

  const { host } = config;
  const model = modelsKeyedById[objectKey];
  const { twinIdField, mapping } = model;

  const largeOffset = !!offset && Object.values(offset as { [v: string]: number })
    .some(v => Math.abs(v) > 100000);
  const isMapped = mapping && !isEmpty(mapping);

  useEffect(() => {
    setTempTwinIdMapping(undefined);
  }, [twinIdField]);

  useEffect(() => {
    if (tempTwinIdMapping) {
      setModelMappingOverride(tempTwinIdMapping);
    } else {
      setModelMappingOverride(mapping);
    }
    setTwinIdProgress(0);
  }, [tempTwinIdMapping, isMapped]);

  const twinIDOverlap = useMemo(
    () => Object.keys(modelsKeyedById)
      .filter(id => modelIsEnabled(id, modelsKeyedById, assetTypesKeyedById))
      .map((id) => {
        if (id === objectKey) return { objectKey: id, overlap: [] };
        const currentModelTwinIDs = Object.keys(mapping);

        const otherMapping = modelsKeyedById[id].mapping;
        const otherModelTwinIDs = Object.keys(otherMapping);
        const overlap = currentModelTwinIDs
          .filter(twinId => otherModelTwinIDs.includes(twinId));
        return {
          objectKey: id,
          overlap,
        };
      })
      .filter(res => res.overlap.length > 0)
      .map((res) => {
        const contents = JSON.stringify(res.overlap, null, 2);
        const blob = new Blob([contents], { type: 'text/json' });
        return (
          <a
            key={res.objectKey}
            href={window.URL.createObjectURL(blob)}
            download={
              `export_overlap_${res.objectKey}_${new Date().toJSON().slice(0, 10)}.json`
            }
          >
            <b>{res.objectKey}</b>
            {` [${res.overlap!.length} twinIDs]`}
          </a>
        );
      }),
    [modelsKeyedById],
  );

  const onTwinLoading = (percentage) => {
    /**
     * Percentage goes backwards sometimes (ie. from 100% -> 95%)
     * This check prevents the loading bar from showing again.
     * */
    if (percentage === 0) {
      setIsTwinLoading(true, digitalTwinDispatch);
    }

    setIsLoadingPercentage(percentage, digitalTwinDispatch);

    if (percentage === 100) {
      setIsTwinLoading(false, digitalTwinDispatch);
    }
  };

  const onPropertiesSave = async (e: FormValues) => {
    setSavingProperties(true);
    const update = { ...model, ...e };
    if (e.twinIdField !== twinIdField) update.mapping = {};
    await updateModel(objectKey, update);
    setSavingProperties(false);
  };

  const onOffsetSave = async () => {
    if (!offset) return;
    setSavingProperties(true);
    setShowTwin(false);
    await updateOffset({
      x: offset.x + modelOffset.x,
      y: offset.y + modelOffset.y,
      z: offset.z + modelOffset.z,
    });
    setSavingProperties(false);
    setShowTwin(true);
  };

  const generateTwinIdMappingStatus = () => {
    if (requestMapping) return (null);
    if (tempTwinIdMapping || savingMap) {
      return (
        <>
          <EyeOutlined />
          Model Preview
        </>
      );
    }
    if (isMapped) {
      return (
        <>
          <CheckCircleOutlined style={{ color: 'green' }} />
          {`Mapped (${Object.keys(mapping).length})`}
        </>
      );
    }
    return (
      <>
        <WarningOutlined style={{ color: 'orange' }} />
        Mapping Required
      </>
    );
  };

  const displayMappingStatus = () => {
    const showModelMappedAlert = isMapped && !requestMapping && !savingMap && !tempTwinIdMapping;
    const translationProgress = apsModel?.manifest?.progress;
    const apsModelTranslating = apsModel && translationProgress !== 'complete';
    return (
      <>
        {(!isEmpty(twinIDOverlap)) && (
        <Alert
          message={(
            <>
              This twin&#39;s mapping has overlapping twinIds with other enabled model(s).
              <br />
              This may cause selection issues when viewing both models at the same time.
              <br />
              <ul style={{ paddingLeft: 20, margin: 0 }}>
                {twinIDOverlap.map(o => (
                  <li key={o.key}>
                    {o}
                  </li>
                ))}
              </ul>
              If you&#39;re planning on disabling the other model(s), please ignore this message.
            </>
        )}
          type="warning"
          showIcon
        />
        )}
        {showModelMappedAlert && (
        <Alert
          message={`The model map was saved successfully.
        If the preview looks correct, no further action is required.`}
          type="success"
          showIcon
        />
        )}
        {apsModelTranslating && (
        <Alert
          message={`The model is still translating (${translationProgress}).
        Please come back to perform TwinId mapping when this is complete.`}
          type="warning"
          showIcon
        />
        )}
        {(isTwinLoading && !apsModelTranslating) && (
        <Alert
          message="Waiting for the model to load..."
          type="info"
          showIcon
        />
        )}
      </>
    );
  };

  const displayMappingResult = () => {
    const validTwinIdMapping = tempTwinIdMapping && !isEmpty(tempTwinIdMapping);
    return (
      <>
        {(tempTwinIdMapping && !isEmpty(tempTwinIdMapping)) && (
        <Alert
          message={`Please check if the model is mapped correctly.
        The preview shows what the model looks like with live assets.
        If no assets are present in the system, the model will not display correctly.`}
          type="info"
          showIcon
        />
        )}
        {(tempTwinIdMapping && isEmpty(tempTwinIdMapping)) && (
        <Alert
          message={`We've analyzed the model 
      and found nothing under the property "${twinIdField}".
      Please double check that this field is correct.`}
          type="info"
          showIcon
        />
        )}
        {
        tempTwinIdMapping
        && (
        <>
          {`Mapped ${Object.keys(tempTwinIdMapping).length} elements`}
          {validTwinIdMapping
            && <TextArea readOnly value={JSON.stringify(tempTwinIdMapping, null, 2)} />}
        </>
        )
      }
      </>
    );
  };

  const onExtractMapping = () => {
    setTwinIdProgress(0);
    setRequestMapping(twinIdField);
    setTempTwinIdMapping(undefined);
  };

  const onMappingConfirmation = async () => {
    setSavingMapping(true);
    await updateMap(
      objectKey,
      tempTwinIdMapping || {},
      twinIdField,
    );
    setSavingMapping(false);
    setTempTwinIdMapping(undefined);
  };

  const mappingFilter = (
    <JsonEditor
      content={{ text: formattedMappingFilters }}
      onChange={(content) => {
        const { text } = content;
        setMappingFilters(text);
      }}
      onReset={() => setMappingFilters(DEFAULT_MAPPING_FILTER)}
      height={300}
    />
  );

  return (
    <div className={styles.container}>
      <div className={styles.modelContainer}>
        {
          showTwin && (
            <ModelViewer
              ref={React.createRef()}
              source={host.twin}
              modelCode="settings"
              objectKey={objectKey}
              twinData={twinData}
              config={JSON.stringify(colorScheme)}
              loadModels={[objectKey]}
              project={project}
              tenant={tenant}
              isTwinLoading={false}
              onTwinLoading={onTwinLoading}
              onTwinReady={(onTwinReady, coordinates) => {
                setOffset(coordinates);
                setIsTwinReady(onTwinReady, digitalTwinDispatch);
              }}
              assetDetailsAssetId={assetDetailsAssetId}
              requestModelMapping={requestMapping}
              mappingIgnoreArray={
                mappingFiltersEdited && isMappingFiltersValid()
                  ? JSON.parse(mappingFilters)
                  : undefined
              }
              onMapProgress={p => setTwinIdProgress(p)}
              onMapComplete={(m) => {
                setRequestMapping(undefined);
                setTempTwinIdMapping(m);
              }}
              mappingOverride={modelMappingOverride}
            />
          )
        }

        <div className={styles.assetTypeDropdownContainer}>
          <Card title="Preview Asset Type" size="small">
            <AssetTypeDropdown size="small" />
          </Card>
        </div>
      </div>
      <div className={styles.settingsContainer}>
        {(!isTwinLoading && largeOffset)
          && (
          <Alert
            message={(
              <>
                <b>Large Offset Detected</b>
                <br />
                {`[${offset.x?.toFixed(1)}, ${offset.y?.toFixed(1)}, ${offset.z?.toFixed(1)}]`}
                <br />
                This may cause stuttering and inaccurate element selections.
              </>
            )}
            type="warning"
            showIcon
            action={(
              <Button
                size="small"
                onClick={() => onOffsetSave()}
                loading={savingProperties}
              >
                Fix
              </Button>
            )}
          />
          )}
        <div className={styles.propertiesContainer}>
          <Typography.Title level={5}>
            Properties
          </Typography.Title>
          <ModalForm
            fields={[
              {
                entryComponent: 'picklist',
                id: 'assetTypes',
                label: 'Enabled Asset Type(s)',
                properties: {
                  options: assetTypes.map(assetType => ({
                    label: assetType.label,
                    value: assetType.id,
                  })),
                  multi: true,
                },
                isRequired: true,
              },
              {
                entryComponent: 'picklist',
                id: 'twinIdField',
                label: 'Twin ID Property Key',
                properties: {
                  options: PRESET_TWIN_ID_NAME.map(label => ({
                    label,
                    value: label,
                  })),
                  tags: true,
                  maxCount: 1,
                },
                isRequired: true,
              },
              {
                entryComponent: 'checkbox',
                id: 'loadMappedIdsOnly',
                label: 'Hide non-mapped elements for users to better performance'
                  + ' (Must be enabled for advanced filters to work)',
              },
            ]}
            submitButtonText="Save"
            onSubmit={onPropertiesSave}
            hideCancelButton
            defaultValues={model}
            submitButtonLoading={savingProperties}
            isDisabled={disableUpdate}
          />
        </div>
        <div className={styles.mappingContainer}>
          <div className={styles.mappingHeader}>
            <Typography.Title level={5}>
              TwinId Mapping
            </Typography.Title>
            <div className={styles.mappingSubHeader}>
              {generateTwinIdMappingStatus()}
            </div>
          </div>
          {displayMappingStatus()}
          <Collapse
            size="small"
            items={[{
              key: '1',
              label: `Advanced Filters${mappingFiltersEdited ? '*' : ''}`,
              children: mappingFilter,
            }]}
          />
          <Button
            onClick={onExtractMapping}
            disabled={
              isTwinLoading
              || savingMap
              || !!tempTwinIdMapping
              || !validMappingFilters()
            }
            loading={!!requestMapping}
          >
            Extract &#34;
            {twinIdField}
            &#34; Mapping
          </Button>
          {(!tempTwinIdMapping) && <Progress percent={twinIDProgress} />}
          {displayMappingResult()}
          <div style={{
            display: 'flex',
            flexDirection: 'row',
            gap: 10,
            justifyContent: 'right',
          }}
          >
            <Button
              disabled={!tempTwinIdMapping || disableUpdate}
              onClick={() => setTempTwinIdMapping(undefined)}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              disabled={!tempTwinIdMapping || disableUpdate}
              loading={savingMap}
              onClick={onMappingConfirmation}
            >
              Save Mapping
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ModelEditor;
