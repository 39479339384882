import {
  REMOVE_ALL_ASSET_STATUS_FILTER,
  REMOVE_ALL_FILTERS,
  REMOVE_ALL_OBSERVATION_TYPE_FILTER,
  REMOVE_ASSET_STATUS_FILTER,
  REMOVE_OPENED_LAYER_ID_FILTER,
  REMOVE_SELECTED_LAYER_IDS_FILTER,
  RESET_SELECTED_ASSETS,
  SELECT_ASSET_STATUS_FILTER,
  SELECT_OBSERVATION_TYPE_FILTER,
  SET_ASSET_LAYER_FILTER,
  SET_COLLAPSABLE,
  SET_DATE_FILTER,
  SET_MULTI_SEARCH_MODE_FILTER,
  SET_OPENED_LAYER_ID_FILTER,
  SET_SEARCH_TAGS,
  SET_SEARCH_TEXT,
  SET_SELECTED_ASSETS,
  SET_SHOW_SELECTED_ONLY,
  SET_SUPPLIER_FILTER,
  SET_TABLE_FILTER,
  SET_TABLE_PAGINATION,
  SET_TABLE_SORTER,
  SET_TIME_TRAVEL_DATE,
  SET_TYPE_FILTER,
} from '../actions/actionTypes';
import { AssetManagerStateType } from '../context';
import { ContextDispatchParam } from '../config/types';
import { IS_MOBILE } from '../config/constants';

const getDefaultFilters = () => {
  const filters = {
    searchTags: [],
    searchText: '',
    dateFilter: [],
    assetStatusFilter: {
      ids: [],
      toggle: null,
    },
    observationTypeFilter: {},
    openedLayerId: null,
    tableFilter: {},
    tableSorter: null,
    supplierFilter: [],
    selectedLayerIds: [],
    timeTravelDate: null,
  };
  return JSON.parse(JSON.stringify(filters));
};

export default (
  state: AssetManagerStateType,
  action: ContextDispatchParam,
): AssetManagerStateType => {
  const { assetManagerId } = action.payload;
  switch (action.type) {
    case SET_SEARCH_TAGS:
      return {
        ...state,
        [assetManagerId]: {
          ...state[assetManagerId],
          filters: {
            ...state[assetManagerId]?.filters,
            searchTags: action.payload.searchTags,
          },
        },
      };
    case SET_SEARCH_TEXT:
      return {
        ...state,
        [assetManagerId]: {
          ...state[assetManagerId],
          filters: {
            ...state[assetManagerId]?.filters,
            searchText: action.payload.searchText,
          },
        },
      };
    case SET_DATE_FILTER:
      return {
        ...state,
        [assetManagerId]: {
          ...state[assetManagerId],
          filters: {
            ...state[assetManagerId]?.filters,
            dateFilter: action.payload.dateFilter,
          },
        },
      };
    case SET_TIME_TRAVEL_DATE:
      return {
        ...state,
        [assetManagerId]: {
          ...state[assetManagerId],
          filters: {
            ...state[assetManagerId]?.filters,
            timeTravelDate: action.payload.timeTravelDate,
          },
        },
      };
    case SET_TYPE_FILTER:
      if (!state[assetManagerId]) {
        return {
          ...state,
          [assetManagerId]: {
            filters: {
              searchTags: [],
              searchText: '',
              dateFilter: [],
              assetStatusFilter: {
                ids: [],
                toggle: null,
              },
              pagination: {
                current: 1,
                pageSize: IS_MOBILE ? 10 : 20,
              },
              multiSearchModeFilter: 'or',
              observationTypeFilter: {},
              openedLayerId: null,
              typeFilter: action.payload.typeFilter,
              tableFilter: {},
              tableSorter: null,
              supplierFilter: [],
              selectedLayerIds: [],
              timeTravelDate: null,
            },
            assetSelection: {
              showSelectedOnly: false,
              selectedAssets: [],
              lastManualUpdate: new Date(),
            },
          },
        };
      }
      return {
        ...state,
        [assetManagerId]: {
          ...state[assetManagerId],
          filters: {
            ...getDefaultFilters(),
            ...state[assetManagerId]?.filters,
            typeFilter: action.payload.typeFilter,
            supplierFilter: [],
            dateFilter: [],
            searchTags: [],
            searchText: '',
            timeTravelDate: null,
            assetStatusFilter: {
              ids: [],
              toggle: null,
            },
            selectedLayerIds: [],
            observationTypeFilter: {},
            tableFilter: {},
            openedLayerId: null,
          },
          assetSelection: {
            ...state[assetManagerId].assetSelection,
            selectedAssets: [],
            showSelectedOnly: false,
          },
        },
      };
    case SELECT_ASSET_STATUS_FILTER: {
      const { id, toggledValue } = action.payload;
      const { ids, toggle } = state[assetManagerId]?.filters.assetStatusFilter || {};

      if (toggle !== toggledValue) {
        return {
          ...state,
          [assetManagerId]: {
            ...state[assetManagerId],
            filters: {
              ...state[assetManagerId]?.filters,
              assetStatusFilter: {
                toggle: toggledValue,
                ids: [id],
              },
            },
          },
        };
      }

      const newIds = ids.includes(id)
        ? ids.filter(idFromState => idFromState !== id)
        : [...ids, id];

      if (newIds.length === 0) {
        return {
          ...state,
          [assetManagerId]: {
            ...state[assetManagerId],
            filters: {
              ...state[assetManagerId]?.filters,
              assetStatusFilter: {
                toggle: null,
                ids: [],
              },
            },
          },
        };
      }

      return {
        ...state,
        [assetManagerId]: {
          ...state[assetManagerId],
          filters: {
            ...state[assetManagerId]?.filters,
            assetStatusFilter: {
              toggle,
              ids: newIds,
            },
          },
        },
      };
    }
    case SELECT_OBSERVATION_TYPE_FILTER: {
      const { id, toggledValue } = action.payload;
      const { observationTypeFilter } = state[assetManagerId].filters;
      const newObservationTypeFilter = { ...observationTypeFilter };
      if (
        (observationTypeFilter[id] && observationTypeFilter[id] === toggledValue)
        || toggledValue === null
      ) {
        delete newObservationTypeFilter[id];
      } else {
        newObservationTypeFilter[id] = toggledValue;
      }
      return {
        ...state,
        [assetManagerId]: {
          ...state[assetManagerId],
          filters: {
            ...state[assetManagerId]?.filters,
            observationTypeFilter: newObservationTypeFilter,
          },
        },
      };
    }
    case SET_ASSET_LAYER_FILTER:
      return {
        ...state,
        [assetManagerId]: {
          ...state[assetManagerId],
          filters: {
            ...state[assetManagerId]?.filters,
            locationSelected: action.payload.locationSelected,
            selectedLayerIds: action.payload.selectedLayerIds,
          },
        },
      };
    case SET_OPENED_LAYER_ID_FILTER:
      return {
        ...state,
        [assetManagerId]: {
          ...state[assetManagerId],
          filters: {
            ...state[assetManagerId]?.filters,
            openedLayerId: action.payload.openedLayerId,
          },
        },
      };
    case SET_SUPPLIER_FILTER:
      return {
        ...state,
        [assetManagerId]: {
          ...state[assetManagerId],
          filters: {
            ...state[assetManagerId]?.filters,
            supplierFilter: action.payload.supplier,
          },
        },
      };
    case SET_TABLE_FILTER:
      return {
        ...state,
        [assetManagerId]: {
          ...state[assetManagerId],
          filters: {
            ...state[assetManagerId]?.filters,
            tableFilter: action.payload.tableFilter,
          },
        },
      };
    case SET_TABLE_SORTER:
      return {
        ...state,
        [assetManagerId]: {
          ...state[assetManagerId],
          filters: {
            ...state[assetManagerId]?.filters,
            tableSorter: action.payload.tableSorter,
          },
        },
      };
    case SET_TABLE_PAGINATION:
      return {
        ...state,
        [assetManagerId]: {
          ...state[assetManagerId],
          filters: {
            ...state[assetManagerId]?.filters,
            pagination: action.payload.pagination,
          },
        },
      };
    case SET_MULTI_SEARCH_MODE_FILTER:
      return {
        ...state,
        [assetManagerId]: {
          ...state[assetManagerId],
          filters: {
            ...state[assetManagerId]?.filters,
            multiSearchModeFilter: action.payload.multiSearchModeFilter,
          },
        },
      };
    case SET_SELECTED_ASSETS:
      return {
        ...state,
        [assetManagerId]: {
          ...state[assetManagerId],
          assetSelection: {
            ...state[assetManagerId]?.assetSelection,
            selectedAssets:
              Array.isArray(action.payload.selectedAssets)
                ? [...new Set(action.payload.selectedAssets)]
                : action.payload.selectedAssets,
            lastManualUpdate:
              (action.payload.manual)
                ? new Date()
                : state[assetManagerId].assetSelection?.lastManualUpdate,
            showSelectedOnly: action.payload.selectedAssets.length === 0
              ? false : state[assetManagerId].assetSelection?.showSelectedOnly,
          },
        },
      };
    case SET_SHOW_SELECTED_ONLY:
      return {
        ...state,
        [assetManagerId]: {
          ...state[assetManagerId],
          assetSelection: {
            ...state[assetManagerId].assetSelection,
            showSelectedOnly: action.payload.showSelectedOnly,
          },
        },
      };
    case REMOVE_ALL_FILTERS:
      return {
        ...state,
        [assetManagerId]: {
          ...state[assetManagerId],
          filters: {
            ...state[assetManagerId]?.filters,
            ...getDefaultFilters(),
          },
        },
      };
    case REMOVE_ASSET_STATUS_FILTER: {
      const { id } = action.payload;
      const { ids, toggle } = state[assetManagerId].filters.assetStatusFilter;
      const filteredIds = ids.filter(statusId => statusId !== id);
      return {
        ...state,
        [assetManagerId]: {
          ...state[assetManagerId],
          filters: {
            ...state[assetManagerId]?.filters,
            assetStatusFilter: {
              toggle: filteredIds.length ? toggle : null,
              ids: filteredIds,
            },
          },
        },
      };
    }
    case REMOVE_ALL_ASSET_STATUS_FILTER:
      return {
        ...state,
        [assetManagerId]: {
          ...state[assetManagerId],
          filters: {
            ...state[assetManagerId]?.filters,
            assetStatusFilter: {
              toggle: null,
              ids: [],
            },
          },
        },
      };
    case REMOVE_ALL_OBSERVATION_TYPE_FILTER:
      return {
        ...state,
        [assetManagerId]: {
          ...state[assetManagerId],
          filters: {
            ...state[assetManagerId]?.filters,
            observationTypeFilter: {},
          },
        },
      };
    case REMOVE_SELECTED_LAYER_IDS_FILTER:
      return {
        ...state,
        [assetManagerId]: {
          ...state[assetManagerId],
          filters: {
            ...state[assetManagerId]?.filters,
            selectedLayerIds: [],
          },
        },
      };
    case REMOVE_OPENED_LAYER_ID_FILTER:
      return {
        ...state,
        [assetManagerId]: {
          ...state[assetManagerId],
          filters: {
            ...state[assetManagerId]?.filters,
            openedLayerId: null,
          },
        },
      };
    case RESET_SELECTED_ASSETS:
      return {
        ...state,
        [assetManagerId]: {
          ...state[assetManagerId],
          assetSelection: {
            selectedAssets: [],
            showSelectedOnly: false,
            lastManualUpdate: new Date(),
            filters: {},
          },
        },
      };
    case SET_COLLAPSABLE:
      return {
        ...state,
        [assetManagerId]: {
          ...state[assetManagerId],
          collapsable: {
            ...state[assetManagerId]?.collapsable,
            [action.payload.id]: action.payload.state,
          },
        },
      };
    default:
      return state;
  }
};
