import { ContextDispatch, PageLayout } from '../config/types';
import {
  SET_ASSET_DETAILS_ID,
  SET_ASSET_DETAILS_LAYOUT,
  SET_DONT_SHOW_PRESENTER_SETTING,
  SET_PAGE_LAYOUT,
  SET_PRESENTER_MODE,
  SET_PRESENTER_MODE_COMPONENTS,
  SET_SUMMARIES_COLLAPSED,
} from './actionTypes';

export const setPageLayout = (
  pageLayout: PageLayout,
  dispatch: ContextDispatch,
) => {
  localStorage.setItem(SET_PAGE_LAYOUT, pageLayout);
  dispatch({
    type: SET_PAGE_LAYOUT,
    payload: pageLayout,
  });
};

export const setAssetDetailsLayout = (
  assetDetaillayout: PageLayout,
  dispatch: ContextDispatch,
) => {
  dispatch({
    type: SET_ASSET_DETAILS_LAYOUT,
    payload: assetDetaillayout,
  });
};

export const setSummariesCollapsed = (
  isCollapsed: boolean,
  dispatch: ContextDispatch,
) => {
  localStorage.setItem(SET_SUMMARIES_COLLAPSED, isCollapsed.toString());
  dispatch({
    type: SET_SUMMARIES_COLLAPSED,
    payload: isCollapsed,
  });
};

export const setAssetDetailsAssetId = (
  assetDetailsId: string | null,
  dispatch: ContextDispatch,
) => {
  dispatch({
    type: SET_ASSET_DETAILS_ID,
    payload: assetDetailsId,
  });
};

export const setPresenterMode = (
  presenterMode: boolean,
  dispatch: ContextDispatch,
) => {
  if (presenterMode) {
    setSummariesCollapsed(false, dispatch);
    setAssetDetailsAssetId(null, dispatch);
  }

  dispatch({
    type: SET_PRESENTER_MODE,
    payload: presenterMode,
  });
};

export const setPresenterModeComponents = (
  enabledSummaries: Array<string>,
  dispatch: ContextDispatch,
) => {
  dispatch({
    type: SET_PRESENTER_MODE_COMPONENTS,
    payload: enabledSummaries,
  });
};

export const setShowPresenterSetting = (
  dontShowPresenterSetting: boolean,
  dispatch: ContextDispatch,
) => {
  localStorage.setItem(SET_DONT_SHOW_PRESENTER_SETTING, dontShowPresenterSetting.toString());
  dispatch({
    type: SET_DONT_SHOW_PRESENTER_SETTING,
    payload: dontShowPresenterSetting,
  });
};
