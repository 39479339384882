import React, {
  createContext, FC, useContext, useReducer,
} from 'react';
import { ContextDispatch } from '../config/types';
import UserReducer from '../reducers/users';

export type UserStateType = {
  user: any
  isFetchingUser: boolean
};

const initialState: UserStateType = {
  user: {},
  isFetchingUser: false,
};

export const UserContext = createContext({
  state: initialState,
  dispatch: (() => { }) as ContextDispatch,
});

export const useUser = (): [UserStateType, ContextDispatch] => {
  const { state, dispatch } = useContext(UserContext);
  return [state, dispatch];
};

export const UserProvider:FC<React.PropsWithChildren<{}>> = ({ children }) => {
  const [state, dispatch] = useReducer(UserReducer, initialState);

  return (
    <UserContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        state,
        dispatch,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
