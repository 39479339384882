import { Environment } from '@ynomia/client';
import { REGION } from './constants';

import production from './production.json';
import production_uk from './production-uk.json';
import staging from './staging.json';
import staging_uk from './staging-uk.json';

const hostConfigs = {
  staging,
  staging_uk,
  production,
  production_uk,
};

export type Region = keyof typeof hostConfigs;
interface HostConfig {
  environment: Environment,
  host: {
    api: string,
    iamApi: string,
    twin: string,
    auth: string,
    clientId: string,
  },
  analytics: {
    enabled: boolean,
    id: string,
  },
  integrations: {
    appziToken: string,
  },
  sentryLogging: {
    enabled: boolean,
    dsn: string
  },
}

export const productionHostName = 'app.ynomia.com';
export const isProduction = productionHostName === window.location.hostname;
export const localStorageRegion = localStorage.getItem(REGION) as Region;

export const resolveRegion = (isEuropean: boolean) => {
  if (isProduction) {
    if (isEuropean) {
      return 'production_uk';
    }
    return 'production';
  }

  if (isEuropean) {
    return 'staging_uk';
  }
  return 'staging';
};

export const getUrlRegion = () => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const urlParamRegion = urlParams.get(REGION);
  return urlParamRegion ? resolveRegion(urlParamRegion === 'uk') : undefined;
};

const getCurrentRegion = (): Region => {
  /**
   * 1. Prioritise url region if specified.
   * Allowing the user to automatically load on the correct region.
   * */
  const urlRegion = getUrlRegion();
  if (urlRegion) {
    if (urlRegion !== localStorageRegion) {
      localStorage.setItem(REGION, urlRegion);
    }
    return urlRegion;
  }

  /**
   * 2. The localstorage value is the most common region
   *    when the user is logged in.
   * */
  if (localStorageRegion) {
    return localStorageRegion;
  }

  /**
   * 3. Try and automatically detect the region.
   *    Typically used when the user first lands on the page.
   * As of April 2023, this works in 95.42% of the browsers used globally.
   * Source: https://stackoverflow.com/a/34602679
   * */
  const timezone = Intl?.DateTimeFormat?.()?.resolvedOptions?.()?.timeZone;
  const isEuropean = timezone?.startsWith('Europe/');
  const autoRegion = resolveRegion(isEuropean);
  return autoRegion;
};

export const hostnameOptions = isProduction
  ? [
    {
      region: 'production_uk',
      urlRegionCode: 'uk',
      label: 'United Kingdom',
    },
    {
      region: 'production',
      urlRegionCode: 'global',
      label: 'Global',
    },
  ] : [
    {
      region: 'staging_uk',
      urlRegionCode: 'uk',
      label: 'United Kingdom (staging)',
    },
    {
      region: 'staging',
      urlRegionCode: 'global',
      label: 'Global (staging)',
    },
  ];

export const currentRegion = getCurrentRegion();

export default hostConfigs[currentRegion] as HostConfig;
