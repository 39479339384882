import React, {
  createContext, FC, useContext, useReducer,
} from 'react';
import { ContextDispatch, Layer } from '../config/types';
import LayerReducer from '../reducers/layers';

export type LayerStateType = {
  layersKeyedById: Map<string, Layer>
  isFetchingLayers: boolean
  lastFetchStartTime: Date | null
};

const initialState: LayerStateType = {
  layersKeyedById: new Map(),
  isFetchingLayers: false,
  lastFetchStartTime: null,
};

export const LayerContext = createContext({
  state: initialState,
  dispatch: (() => { }) as ContextDispatch,
});

export const useLayer = (): [LayerStateType, ContextDispatch] => {
  const { state, dispatch } = useContext(LayerContext);
  return [state, dispatch];
};

export const LayerProvider:FC<React.PropsWithChildren<{}>> = ({ children }) => {
  const [state, dispatch] = useReducer(LayerReducer, initialState);

  return (
    <LayerContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        state,
        dispatch,
      }}
    >
      {children}
    </LayerContext.Provider>
  );
};
