import { createSelector } from 'reselect';
import {
  getAssetDataSource,
  getColumnFilters,
} from '../../utils';
import {
  getAssetManagerAssetSelection,
  getAssetManagerFilters,
  getAssetStatusesKeyedById,
  getCustomColumns,
  getLinkedDevicesKeyedByAssetId,
  getListViewColumns,
} from '..';
import {
  getDataSourceKeyedByAssetId,
  getFilteredAssets,
  getFilteredAssetsContext,
  getShowOnlySelectedAssets,
} from '.';
import map from '../map';

export const getFilteredAssetsForListViewTableFilter = createSelector(
  [
    getAssetManagerFilters,
    getFilteredAssetsContext,
  ],
  (
    assetManagerFilters,
    filteredAssetsContext,
  ) => {
    const {
      searchTags,
      searchText,
      dateFilter,
      typeFilter,
      assetStatusFilter,
      observationTypeFilter,
      selectedLayerIds,
      openedLayerId,
      supplierFilter,
      multiSearchModeFilter,
    } = assetManagerFilters;
    return getFilteredAssets(filteredAssetsContext, {
      searchTags,
      searchText,
      dateFilter,
      typeFilter,
      assetStatusFilter,
      observationTypeFilter,
      selectedLayerIds,
      openedLayerId,
      supplierFilter,
      multiSearchModeFilter,
    });
  },
);

export const getFilteredAssetsForListView = createSelector(
  [
    getAssetManagerFilters,
    getFilteredAssetsContext,
  ],
  (
    assetManagerFilters,
    filteredAssetsContext,
  ) => {
    const {
      searchTags,
      searchText,
      dateFilter,
      typeFilter,
      assetStatusFilter,
      observationTypeFilter,
      selectedLayerIds,
      tableFilter,
      openedLayerId,
      supplierFilter,
      multiSearchModeFilter,
    } = assetManagerFilters;
    return getFilteredAssets(filteredAssetsContext, {
      searchTags,
      searchText,
      dateFilter,
      typeFilter,
      assetStatusFilter,
      observationTypeFilter,
      selectedLayerIds,
      tableFilter,
      openedLayerId,
      supplierFilter,
      multiSearchModeFilter,
    });
  },
);

export const getFilteredDataSource = createSelector(
  [
    getFilteredAssetsForListView,
    getDataSourceKeyedByAssetId,
  ],
  (
    filteredAssetsForAssetsTable,
    listViewDataSource,
  ) => filteredAssetsForAssetsTable.map(a => listViewDataSource.get(a.id)!),
);

export const getShowOnlySelectedAssetsDataSource = createSelector(
  [
    map.layersKeyedById,
    map.assetsKeyedById,
    getShowOnlySelectedAssets,
    getCustomColumns,
    getAssetStatusesKeyedById,
    getLinkedDevicesKeyedByAssetId,
  ],
  (
    layersKeyedById,
    assetsKeyedById,
    showOnlySelectedAssets,
    customColumns,
    assetStatusesKeyedById,
    linkedDevicesKeyedByAssetId,
  ) => getAssetDataSource(
    showOnlySelectedAssets,
    customColumns,
    layersKeyedById,
    assetsKeyedById,
    assetStatusesKeyedById,
    linkedDevicesKeyedByAssetId,
  ),
);

export const getShowOnlySelectedAssetsDataSourceForListView = createSelector(
  [
    map.layersKeyedById,
    map.assetsKeyedById,
    getShowOnlySelectedAssets,
    getCustomColumns,
    getAssetStatusesKeyedById,
    getLinkedDevicesKeyedByAssetId,
  ],
  (
    layersKeyedById,
    assetsKeyedById,
    showOnlySelectedAssets,
    customColumns,
    assetStatusesKeyedById,
    linkedDevicesKeyedByAssetId,
  ) => getAssetDataSource(
    showOnlySelectedAssets,
    customColumns,
    layersKeyedById,
    assetsKeyedById,
    assetStatusesKeyedById,
    linkedDevicesKeyedByAssetId,
  ),
);

export const getColumnFiltersForAssetsTable = createSelector(
  [
    map.timezone,
    getAssetManagerFilters,
    getAssetManagerAssetSelection,
    getListViewColumns,
    getDataSourceKeyedByAssetId,
    getFilteredDataSource,
  ],
  (
    timezone,
    assetManagerFilters,
    assetManagerAssetSelection,
    listViewColumns,
    listViewDataSource,
    filteredDataSource,
  ) => {
    const { tableSorter, tableFilter } = assetManagerFilters;
    const { showSelectedOnly } = assetManagerAssetSelection;
    return getColumnFilters(
      tableFilter,
      tableSorter,
      timezone,
      showSelectedOnly,
      listViewColumns,
      listViewDataSource,
      filteredDataSource,
    );
  },
);

export const getDataSourceForListView = createSelector(
  [
    getFilteredAssetsForListView,
    getDataSourceKeyedByAssetId,
  ],
  (
    filteredAssetsForAssetsTable,
    listViewDataSource,
  ) => filteredAssetsForAssetsTable.map(a => listViewDataSource.get(a.id)!),
);
