import React, {
  createContext, FC, useContext, useReducer,
} from 'react';
import { InMemoryCacheData } from '@ynomia/client';
import ClientCacheReducer from '../reducers/clientCache';
import { ContextDispatch } from '../config/types';

export type ClientCacheStateType = {
  clientCache: InMemoryCacheData | null
};

const initialState: ClientCacheStateType = {
  clientCache: null,
};

export const ClientCacheContext = createContext({
  state: initialState,
  dispatch: (() => { }) as ContextDispatch,
});

export const useClientCache = (): [ClientCacheStateType, ContextDispatch] => {
  const { state, dispatch } = useContext(ClientCacheContext);
  return [state, dispatch];
};

export const ClientCacheProvider:FC<React.PropsWithChildren<{}>> = ({ children }) => {
  const [state, dispatch] = useReducer(ClientCacheReducer, initialState);

  return (
    <ClientCacheContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        state,
        dispatch,
      }}
    >
      {children}
    </ClientCacheContext.Provider>
  );
};
