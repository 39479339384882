import {
  Button, Drawer, Dropdown, Menu,
} from 'antd';
import {
  Link, useLocation, useParams, useSearchParams,
} from 'react-router-dom';
import React, { FC, useState } from 'react';
import { DownOutlined } from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { MenuProps } from 'antd';
import { UserInfo } from '@ynomia/client';
import { currentRegion, hostnameOptions } from '../../../config';
import {
  getMenuFromBootstrap,
  getMenuItemsFromBootstrap,
  getProjects,
  getUserInfo,
} from '../../../selectors';
import CloseIcon from '../../../images/close.svg?react';
import MenuIcon from '../../../images/menu.svg?react';
import { LOGIN_HINT, PROJECT_SLUG, REGION } from '../../../config/constants';
import YnomiaFullLogoDark from '../../../images/ynomia-full-logo-dark.svg?react';
import { getContextStores } from '../../../context';
import { getDecoratedPath } from '../../../services';
import styles from './styles.module.less';

const SideMenu: FC = () => {
  /* Context  */
  const contextStores = getContextStores();

  /* Selectors */
  const menuData = getMenuFromBootstrap(contextStores);
  const menuItems = getMenuItemsFromBootstrap(contextStores);
  const location = useLocation();
  const userInfo = getUserInfo(contextStores);
  const projects = getProjects(contextStores);

  const { email } = userInfo as UserInfo;
  const [searchParams] = useSearchParams();
  const { projectSlug } = useParams();
  const [open, setOpen] = useState(false);
  const selectedKey = menuItems.find(route => route.path === location.pathname);

  const onSwitchRegion = (urlRegionCode: string) => {
    searchParams.append(REGION, urlRegionCode);
    localStorage.setItem(LOGIN_HINT, email);
    // Hard refresh page as we want to read from new localstorage value
    window.location.href = `/redirect?${searchParams}`;
  };

  const menuRegion: MenuProps['items'] = hostnameOptions.map(({ urlRegionCode, label }) => ({
    label,
    key: urlRegionCode,
    onClick: () => onSwitchRegion(urlRegionCode),
  }));

  const processedMenuData = menuData.map(({ menu }) => menu?.map((route) => {
    let { path } = route;
    if (projectSlug && route?.path.includes(PROJECT_SLUG)) {
      path = getDecoratedPath({ projectSlug }, path);
    }
    return {
      key: route?.id,
      icon: <FontAwesomeIcon icon={route?.icon} />,
      label: <Link to={path}>{route?.label}</Link>,
    };
  })).flat();

  const currentRegionLabel = hostnameOptions.find(option => option.region === currentRegion)?.label;

  const onClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button
        type="text"
        onClick={() => setOpen(true)}
        icon={<MenuIcon height="18" />}
        style={{ marginTop: 3 }}
      />
      <Drawer
        title={(
          <div className={styles.drawerTitle}>
            <Button type="text" onClick={() => setOpen(false)} icon={<CloseIcon />} />
            <div className={styles.logo}>
              <Link to="/">
                <YnomiaFullLogoDark />
              </Link>
            </div>
          </div>
      )}
        placement="left"
        closable={false}
        style={{ height: '100%' }}
        onClose={onClose}
        width={310}
        open={open}
      >
        <div style={{ height: '92%' }}>
          <div style={{ paddingBottom: '24px' }}>
            <Menu
              mode="inline"
              items={processedMenuData}
              selectedKeys={[selectedKey?.id || '']}
              onClick={onClose}
            />
          </div>
          {userInfo?.projects?.length !== projects?.length
          && (
          <div className={styles.updateRegionContainer}>
            <div className={styles.updateRegionTitleContainer}>
              <span className={styles.updateRegionTitle}>Update Region</span>
            </div>
            <Dropdown menu={{ items: menuRegion, selectable: true }} trigger={['click']}>
              <div className={styles.menuRegion}>
                {currentRegionLabel}
                <DownOutlined />
              </div>
            </Dropdown>
          </div>
          )}
        </div>
      </Drawer>
    </>
  );
};

export default SideMenu;
