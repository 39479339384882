import { AssetType } from '@ynomia/core/dist/blueprint';
import { Blueprint } from '@ynomia/core/dist/project/blueprint';

export const calculateLoadingPercentage = (
  modelByKeys: { [key: string]: any },
  individualModels: Array<string>,
): number => {
  const sum = individualModels.reduce(
    (accumulator, key) => accumulator + (modelByKeys[key]?.loading || 0),
    0,
  );

  return Math.trunc(sum / individualModels.length) || 0;
};

export const modelIsEnabled = (
  key: string,
  modelsKeyedById: Blueprint['models']['forge']['settings']['individualModels'],
  assetTypesKeyedById: Map<string, AssetType>,
) => modelsKeyedById[key]?.assetTypes?.reduce(
  (acc, type) => !!(acc && assetTypesKeyedById?.get(type)?.individualModelIds?.includes(key)),
  true,
);
