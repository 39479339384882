import React, { FC } from 'react';
import { Layout, Typography } from 'antd';
import { Link } from 'react-router-dom';
import styles from './styles.module.less';

interface Props {
  code: string,
}

const Error: FC<Props> = ({ code }) => {
  const { Title } = Typography;

  return (
    <Layout>
      <div className={styles.siteLayout}>
        <div className={styles.container}>
          <div>
            <Title>
              Server Error
              {`${code}`}
            </Title>
            <Title level={5}>This page is deprecated, deleted, or does not exist at all</Title>
            <Link to="/">Go Home</Link>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Error;
