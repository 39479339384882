import React, { useEffect, useRef } from 'react';
import { Bootstrap } from '@ynomia/core/dist/blueprint';
import { RedirectLoginResult } from '@auth0/auth0-spa-js';
import { useAuth0 } from '@auth0/auth0-react';
import { PROJECT_SLUG, REDIRECT_TO, USER_UNKNOWN } from '../../config/constants';
import {
  analytics, authService, bootstrap, cacheObserver, client, getDecoratedPath,
  getDefaultRoute,
} from '../../services';
import { generateProjectSlug } from '../../utils/url';
import { getContextStores } from '../../context';

const Auth0CallbackView: React.FC = () => {
  /* Context */
  const { clientCacheDispatch } = getContextStores();

  const { handleRedirectCallback } = useAuth0();
  // React strict mode runs everything twice, prevent this for this page
  const initialLoad = useRef(true);

  const processLogin = async () => {
    // Talk to Auth0 first to check if the login was successful
    let redirectCallback: RedirectLoginResult;
    try {
      redirectCallback = await handleRedirectCallback();
    } catch (e: any) {
      alert(`${e.error}: ${e.message}`);
      client.logout(USER_UNKNOWN);
      return;
    }

    client.configureBaseURL();
    cacheObserver.initialize({ onChange$: client.cache.onChange$ }, clientCacheDispatch);
    await authService.login();

    // Track successful logins
    analytics.trackEvent('User Login');

    let projects: Bootstrap['projects'] | undefined;
    let project: Bootstrap['project'] | undefined;
    try {
      ({ projects, project } = await bootstrap());
    } catch (e: any) {
      // Internal backend issue. Since we have only just signed in, take them back to
      // the login screen.
      client.logout();
      return;
    }

    if (!projects?.length) {
      alert('No projects to show!');
      client.logout();
      return;
    }

    const redirectTo = redirectCallback?.appState?.[REDIRECT_TO] as string | undefined;
    let result = getDefaultRoute(redirectTo);
    if (result === '/login') {
      client.logout();
      return;
    }

    if (project) {
      const projectSlug = generateProjectSlug(
        project.metadata.name,
        project.metadata.id,
      );
      if (projectSlug && result.includes(PROJECT_SLUG)) {
        result = getDecoratedPath({ projectSlug }, result);
      }
    }

    window.location.href = result;
  };

  useEffect(() => {
    if (initialLoad.current) {
      initialLoad.current = false;
      try {
        processLogin();
      } catch (e) {
        client.logout();
      }
    }
  }, []);

  return (
    null
  );
};

export default Auth0CallbackView;
