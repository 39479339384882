import {
  Button,
  ConfigProvider,
  DatePicker,
  Dropdown,
  MenuProps,
  Tooltip,
} from 'antd';
import {
  DoubleLeftOutlined,
  DoubleRightOutlined,
  DownOutlined,
  HistoryOutlined,
  LeftOutlined,
  RightOutlined,
} from '@ant-design/icons';
import React, { useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import locale from 'antd/es/locale/en_US';
import {
  checkFeatureTimeTravelControl,
  getAssetManagerFilters,
  getPageFeatures,
} from '../../../selectors';
import {
  convertTimezoneTimeToLocalizedTime,
  disabledDate,
  getProjectTimezone,
  getRangePresets,
  toProjectDate,
} from '../../../utils';
import {
  setDateFilter,
  setTimeTravelDate,
} from '../../../actions';
import { analytics } from '../../../services';
import { getContextStores } from '../../../context';
import styles from './styles.module.less';

const { RangePicker } = DatePicker;

const DateControls = () => {
  /* Context */
  const contextStores = getContextStores();
  const { featureState, assetManagerDispatch } = contextStores;
  const { assetManagerId } = featureState;

  /* Selectors */
  const { timeTravel, dateRange } = getPageFeatures(contextStores);
  const assetManagerFilters = getAssetManagerFilters(contextStores);
  const isFeatureTimeTravelControl = checkFeatureTimeTravelControl(contextStores);

  const labelKeyedById = {
    date_range: 'Date Range',
    time_travel: 'Time Travel',
  };
  const { dateFilter, timeTravelDate } = assetManagerFilters;
  const timeTravelAllowed = isFeatureTimeTravelControl && timeTravel;
  const [itemSelected, setItemSelected] = useState<'time_travel' | 'date_range'>(
    (timeTravelAllowed && !!timeTravelDate) ? 'time_travel' : 'date_range',
  );

  const PROJ_TZ = getProjectTimezone();

  const menuItems: NonNullable<MenuProps['items']> = [
    dateRange && { label: 'Date Range', key: 'date_range' },
    timeTravelAllowed && { label: 'Time Travel', key: 'time_travel' },
  ].filter(Boolean) as NonNullable<MenuProps['items']>;

  const endOfDay = dayjs().endOf('day');
  const dayjsConvertTimezoneTimeToLocalizedTime = (inputDate?: Dayjs | null) => (inputDate
    ? dayjs(convertTimezoneTimeToLocalizedTime(
      inputDate.toString(),
      PROJ_TZ,
    ))
    : null);

  const localizedDateRangeTime: [Dayjs | null, Dayjs | null] = [
    dayjsConvertTimezoneTimeToLocalizedTime(dateFilter[0]),
    dayjsConvertTimezoneTimeToLocalizedTime(dateFilter[1]),
  ];

  const localizedTimeTravelDate = dayjsConvertTimezoneTimeToLocalizedTime(timeTravelDate);

  const onTimeTravelChange = (date: Dayjs | undefined | null) => {
    if (date) {
      const newDate = toProjectDate(date).endOf('day');
      setTimeTravelDate(newDate, assetManagerId, assetManagerDispatch);
    } else {
      setTimeTravelDate(null, assetManagerId, assetManagerDispatch);
    }
  };

  const onRangeChange = (dates: null | (Dayjs | null)[]) => {
    analytics.trackEvent('Date Range Changed');
    if (dates && dates[0] && dates[1]) {
      const res: [Dayjs, Dayjs] = [
        toProjectDate(dates[0]).startOf('day'),
        toProjectDate(dates[1]).endOf('day'),
      ];
      setDateFilter(
        res,
        assetManagerId,
        assetManagerDispatch,
      );
    } else {
      setDateFilter([], assetManagerId, assetManagerDispatch);
    }
  };

  const renderTimeTravel = () => (
    <div className={styles.timeTravelContainer}>
      <Button
        onClick={() => {
          const date = localizedTimeTravelDate || endOfDay;
          analytics.trackEvent('Time Travel Changed', { action: 'Month Subtracted' });
          onTimeTravelChange(date.subtract(1, 'month'));
        }}
        icon={<DoubleLeftOutlined />}
        className={styles.timeTravelButton}
        type="text"
      />
      <Button
        onClick={() => {
          analytics.trackEvent('Time Travel Changed', { action: 'Day Subtracted' });
          const date = localizedTimeTravelDate || dayjs().endOf('day');
          onTimeTravelChange(date.subtract(1, 'day'));
        }}
        icon={<LeftOutlined />}
        className={styles.timeTravelButton}
        type="text"
      />
      <Tooltip
        title="You can go back in time to see the state of the project at the time chosen"
      >
        <DatePicker
          onChange={(ev) => {
            analytics.trackEvent('Time Travel Changed', { action: 'Changed' });
            onTimeTravelChange(ev);
          }}
          format="DD/MM/YYYY"
          suffixIcon={<HistoryOutlined />}
          showNow={false}
          placeholder="Time travel"
          disabledDate={current => disabledDate(current, 'past')}
          value={localizedTimeTravelDate}
          size="large"
          className={styles.datePicker}
        />
      </Tooltip>
      <Button
        disabled={!timeTravelDate || !timeTravelDate.add(1, 'day').isSameOrBefore(endOfDay)}
        onClick={() => {
          analytics.trackEvent('Time Travel Changed', { action: 'Day Added' });
          onTimeTravelChange(localizedTimeTravelDate?.add(1, 'day'));
        }}
        icon={<RightOutlined />}
        className={styles.timeTravelButton}
        type="text"
      />
      <Button
        disabled={!timeTravelDate || !timeTravelDate.add(1, 'month').isSameOrBefore(endOfDay)}
        onClick={() => {
          analytics.trackEvent('Time Travel Changed', { action: 'Month Added' });
          onTimeTravelChange(localizedTimeTravelDate?.add(1, 'month'));
        }}
        icon={<DoubleRightOutlined />}
        className={styles.timeTravelButton}
        type="text"
      />
    </div>
  );

  const renderDateRange = () => (
    <ConfigProvider locale={locale}>
      <Tooltip title="Filter by asset updated date in the period of">
        <RangePicker
          presets={getRangePresets()}
          style={{
            height: '100%',
            flex: 1,
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
          }}
          format="DD/MM/YYYY"
          onChange={dates => onRangeChange(dates as null | (Dayjs | null)[])}
          defaultValue={dateFilter?.length ? dateFilter : undefined}
          value={(dateFilter?.length === 2) ? localizedDateRangeTime : undefined}
          placement="bottomRight"
          disabledDate={current => disabledDate(current, 'past')}
          disabled={!!timeTravelDate}
        />
      </Tooltip>
    </ConfigProvider>
  );

  return menuItems.length > 0 ? (
    <div className={styles.dateControlContainer}>
      <Dropdown
        menu={{
          items: menuItems,
          onClick: (e) => {
            setDateFilter([], assetManagerId, assetManagerDispatch);
            setTimeTravelDate(null, assetManagerId, assetManagerDispatch);
            setItemSelected(e.key as 'date_range' | 'time_travel');
          },
        }}
      >
        <Button className={styles.dateSelectorContainer}>
          {labelKeyedById[itemSelected]}
          <DownOutlined />
        </Button>
      </Dropdown>
      <div className={styles.dateControl}>
        {itemSelected === 'time_travel' && renderTimeTravel()}
        {itemSelected === 'date_range' && renderDateRange()}
      </div>
    </div>
  ) : <div />;
};

export default DateControls;
