import analytics from './Analytics';
import client from './Client';

export async function patchObservationDate(
  tenant: string,
  projectCode: string,
  observationID: string,
  date: Date,
) {
  analytics.trackEvent('Observation Edited');
  await client.server.patch(
    `/scratch/telemetry/observations/${tenant}/${projectCode}/${observationID}/date`,
    {
      date,
    },
  );
}

export async function deleteObservation(
  tenant: string,
  projectCode: string,
  observationID: string,
) {
  analytics.trackEvent('Observation Deleted');
  await client.server.delete(
    `/scratch/telemetry/observations/${tenant}/${projectCode}/${observationID}`,
  );
}
