import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import {
  MenuButton,
  MenuItem,
  MenuSeparator,
  Mode,
  JSONEditor as VanillaJsonEditor,
} from 'vanilla-jsoneditor';
import React, { FC, useEffect, useRef } from 'react';
import { faExpandArrowsAlt, faMinimize, faRefresh } from '@fortawesome/free-solid-svg-icons';

interface Props {
  height?: number,
  // eslint-disable-next-line react/no-unused-prop-types
  content: any,
  // eslint-disable-next-line react/no-unused-prop-types
  readOnly?: boolean
  // eslint-disable-next-line react/no-unused-prop-types
  onChange: (value) => void
  onReset: (value) => void
}

const JsonEditor: FC<Props> = (props) => {
  const refContainer: any = useRef(null);
  const refEditor: any = useRef(null);
  const handle = useFullScreenHandle();
  const { enter, exit, active } = handle;

  const handleRenderMenu = (items: MenuItem[]): MenuItem[] | undefined => {
    const separator: MenuSeparator = {
      type: 'separator',
    };

    const fullScreenButton: MenuButton = {
      type: 'button',
      onClick: active ? exit : enter,
      icon: active ? faMinimize : faExpandArrowsAlt,
      title: 'Fullscreen',
      className: 'custom-expand',
    };

    const revertButton: MenuButton = {
      type: 'button',
      onClick: (value) => {
        props.onReset(value);
        refEditor.current.updateProps(props);
      },
      icon: faRefresh,
      title: 'Reset',
      className: 'custom-expand',
    };

    const head = items.slice(0, items.length - 1);
    const tail = items.slice(items.length - 1); // the tail contains space

    return [...head, revertButton, separator, fullScreenButton, ...tail];
  };

  useEffect(() => {
    refEditor.current = new VanillaJsonEditor({
      target: refContainer.current,
      props: {
        navigationBar: false,
        onRenderMenu: handleRenderMenu,
        mode: 'text' as Mode,
        ...props,
      },
    });

    return () => {
      if (refEditor.current) {
        refEditor.current.destroy();
        refEditor.current = null;
      }
    };
  }, [active]);

  useEffect(() => {
    if (refEditor.current) {
      refEditor.current.updateProps(props);
    }
  }, [props]);

  const autoHeight = active ? window.screen.height : window.screen.height / 2;
  return (
    <FullScreen handle={handle}>
      <div
        style={{ height: props.height ?? autoHeight }}
        ref={refContainer}
      />
    </FullScreen>
  );
};

export default JsonEditor;
