import {
  SET_CREATING_VISIBILITY_FILTER_LOADING,
  SET_FETCHING_VISIBILITY_FILTER,
  SET_UPDATING_VISIBILITY_FILTER_LOADING,
  SET_VISIBILITY_FILTER,
} from '../actions/actionTypes';
import { ContextDispatchParam } from '../config/types';
import { VisibilityFilterStateType } from '../context';

export default (
  state: VisibilityFilterStateType,
  action: ContextDispatchParam,
): VisibilityFilterStateType => {
  switch (action.type) {
    // GET
    case SET_VISIBILITY_FILTER:
      return {
        ...state,
        visibilityFiltersKeyedById: action.payload,
      };
    case SET_FETCHING_VISIBILITY_FILTER:
      return {
        ...state,
        isFetchingVisibilityFilters: action.payload,
      };
    // POST
    case SET_CREATING_VISIBILITY_FILTER_LOADING:
      return {
        ...state,
        isCreatingNewVisibilityFilter: action.payload,
      };
    // PATCH
    case SET_UPDATING_VISIBILITY_FILTER_LOADING:
      return {
        ...state,
        isUpdatingVisibilityFilter: action.payload,
      };

    default:
      return state;
  }
};
