import { DatePicker, TimePicker } from 'antd';
import React, { useMemo } from 'react';
import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import localeData from 'dayjs/plugin/localeData';
import { getProjectTimezone } from '../../../utils';
import DateTimeSplit from './DateTimeSplit';

dayjs.extend(localeData);
dayjs.extend(isToday);

interface Props {
  restriction?: 'past' | 'future';
  mode: 'date' | 'time' | 'datetime' | 'dateTimeSplit'
  format?: string,
  value?: Date | null;
  onChange: (value: string | null) => void
  disabled: boolean
  allowClear: boolean
  style?: React.CSSProperties
}

const DateTime: React.FC<Props> = ({
  restriction,
  mode,
  onChange,
  value,
  disabled,
  format,
  allowClear,
  style,
}) => {
  const PROJ_TZ = getProjectTimezone();
  const formattedDateValue = value ? dayjs(value) : null;

  const onChangeHandler = (newVal: Dayjs | null): void => {
    onChange(newVal?.toDate()?.toISOString() ?? null);
  };

  const range = (start: number, end: number) => {
    const result: Array<number> = [];
    for (let i = start; i < end; i += 1) {
      result.push(i);
    }
    return result;
  };

  const disabledDateTime = (currentDate) => {
    if (restriction === 'past' && dayjs(currentDate)?.isToday()) {
      return {
        disabledHours: () => range(dayjs().tz(PROJ_TZ).hour() + 1, 24),
        disabledMinutes: () => (dayjs(currentDate).hour() === dayjs().tz(PROJ_TZ).hour()
          ? range(dayjs().tz(PROJ_TZ).minute() + 1, 60) : []),
      };
    } if (restriction === 'future' && dayjs(currentDate)?.isToday()) {
      return {
        disabledHours: () => range(0, dayjs().tz(PROJ_TZ).hour() + 1),
        disabledMinutes: () => (dayjs(currentDate).hour() === dayjs().tz(PROJ_TZ).hour()
          ? range(0, dayjs().tz(PROJ_TZ).minute() + 1) : []),
      };
    }
    return {};
  };

  const renderDatePicker = () => {
    switch (mode) {
      case 'time':
        return (
          <TimePicker
            onChange={newVal => onChangeHandler(newVal)}
            disabled={disabled}
            style={{ width: '100%' }}
            value={formattedDateValue}
            allowClear={allowClear}
            disabledTime={disabledDateTime}
          />
        );
      case 'date':
        return (
          <DatePicker
            onChange={newVal => onChangeHandler(newVal)}
            format={format || 'DD/MM/YYYY'}
            disabled={disabled}
            style={{ width: '100%' }}
            value={formattedDateValue}
            maxDate={restriction === 'past' ? dayjs().tz(PROJ_TZ) : undefined}
            minDate={restriction === 'future' ? dayjs().tz(PROJ_TZ) : undefined}
            allowClear={allowClear}
          />
        );
      case 'dateTimeSplit':
        return (
          <DateTimeSplit
            onChange={newVal => onChangeHandler(newVal)}
            format={format || 'DD/MM/YYYY'}
            disabled={disabled}
            style={{ width: '100%', ...style }}
            value={formattedDateValue}
            restriction={restriction}
            allowClear={allowClear}
          />
        );
      case 'datetime':
        return useMemo(() => (
          <DatePicker
            onChange={newVal => onChangeHandler(newVal)}
            format={format || 'DD/MM/YYYY HH:mm'}
            style={{ display: 'block', width: '100%' }}
            disabled={disabled}
            maxDate={restriction === 'past' ? dayjs().tz(PROJ_TZ) : undefined}
            minDate={restriction === 'future' ? dayjs().tz(PROJ_TZ) : undefined}
            disabledTime={disabledDateTime}
            value={formattedDateValue}
            allowClear={allowClear}
            showTime
          />
        ), [formattedDateValue?.toISOString()]);
      default:
        return null;
    }
  };

  return (
    <>
      {renderDatePicker()}
    </>
  );
};

export default DateTime;
