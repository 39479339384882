import { createSelector } from 'reselect';
import { getAssetManagerFilters, getAssetTypes } from '..';
import { getFilteredAssets, getFilteredAssetsContext } from '.';
import { getAssetsKeyedByStatus } from '../../utils';

const getFilteredAssetsForSupplierSummary = createSelector(
  [
    getAssetManagerFilters,
    getFilteredAssetsContext,
  ],
  (
    assetManagerFilters,
    filteredAssetsContext,
  ) => {
    const {
      searchTags,
      searchText,
      dateFilter,
      typeFilter,
      assetStatusFilter,
      observationTypeFilter,
      tableFilter,
      openedLayerId,
      multiSearchModeFilter,
      selectedLayerIds,
    } = assetManagerFilters;
    if (
      !searchTags
      && !dateFilter?.length
      && !typeFilter
      && !selectedLayerIds
      && !assetStatusFilter?.ids?.length
      && !observationTypeFilter.size
    ) {
      return filteredAssetsContext.assetsArray;
    }

    return getFilteredAssets(filteredAssetsContext, {
      searchTags,
      searchText,
      dateFilter,
      typeFilter,
      selectedLayerIds,
      assetStatusFilter,
      observationTypeFilter,
      tableFilter,
      openedLayerId,
      multiSearchModeFilter,
    });
  },
);

export const getAssetsKeyedByStatusForSupplierSummary = createSelector(
  [getAssetTypes, getFilteredAssetsForSupplierSummary],
  getAssetsKeyedByStatus,
);
