import {
  Button, Col, Form, Modal, Popconfirm, Row,
} from 'antd';
import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import React, { FC, useState } from 'react';
import {
  getScratchProjectCode,
  getSelectedAssetType,
  getTenant,
} from '../../../../../selectors';
import { removeObservation, updateObservationDate } from '../../../../../actions';
import ModalForm from '../../../../atoms/ModalForm';
import { ObservationListItem } from '../../../../../config/types';
import { analytics } from '../../../../../services';
import { getContextStores } from '../../../../../context';
import { getProjectTimezone } from '../../../../../utils';
import styles from './styles.module.less';

interface Props {
  observation: ObservationListItem
}

const EditObservation: FC<Props> = ({ observation }) => {
  /* Context  */
  const contextStores = getContextStores();
  const { assetState, assetDispatch } = contextStores;
  const { lastFetchStartTime } = assetState;

  /* Selectors */
  const projectCode = getScratchProjectCode(contextStores);
  const tenant = getTenant(contextStores);
  const selectedAssetType = getSelectedAssetType(contextStores);
  const [open, setOpen] = useState<boolean>(false);
  const [form] = Form.useForm();
  const projTimezone = getProjectTimezone();

  const [submitLoading, setSubmitLoading] = useState(false);

  const observationRaw = observation?.observation_raw || null;
  const {
    timestamp, title, isAutoFill, canEditDate,
  } = observation || {};

  const showModal = () => setOpen(true);
  const onCancel = () => setOpen(false);

  const handleRemoveObservation = () => {
    const observationId = observationRaw?._id || '';

    analytics.trackEvent(
      'Create Asset Submitted',
      { observation_id: observationId as unknown as string },
    );

    removeObservation(
      observationId,
      tenant,
      projectCode,
      [selectedAssetType],
      lastFetchStartTime,
      assetDispatch,
    );
    setOpen(false);
  };

  const defaultValues = {
    dateObservation: isAutoFill ? null : timestamp,
    time: isAutoFill ? null : timestamp,
  };

  const onFinish = async (values) => {
    const { dateObservation } = values;
    if (observation) {
      setSubmitLoading(true);

      await updateObservationDate(
        observationRaw?._id || '',
        tenant,
        projectCode,
        dateObservation,
        [selectedAssetType],
        lastFetchStartTime,
        assetDispatch,
      );
      setSubmitLoading(false);
    }

    setOpen(false);
  };

  return (
    <div key={JSON.stringify(observation)}>
      {!canEditDate && (
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions
        <div
          className={styles.statusEditContainer}
          onClick={showModal}
        />
      )}
      {isAutoFill && canEditDate ? (
        <div
          className={styles.statusEditContainer}
          onClick={showModal}
          role="button"
          tabIndex={0}
        >
          <PlusOutlined className={styles.addDateIcon} />
          <div className={styles.addDateText}>Add date</div>
        </div>
      ) : (
        <EditOutlined
          className={styles.statusEditIcon}
          onClick={showModal}
        />
      )}
      <Modal
        title={title}
        className="editHistory"
        open={open}
        destroyOnClose
        width={342}
        onOk={form.submit}
        closable={false}
        footer={null}
      >
        <ModalForm
          submitButtonLoading={submitLoading}
          fields={[
            {
              disabled: false,
              entryComponent: 'datetime',
              id: 'dateObservation',
              isRequired: false,
              label: 'Date',
              properties: {
                restriction: 'past',
                timezone: projTimezone,
                format: 'MMM DD, YYYY',
                allowClear: true,
                split: true,
              },
              show: undefined,
              validationConditions: undefined,
            },
          ]}
          onCancel={onCancel}
          onSubmit={onFinish}
          defaultValues={defaultValues}
        />
        {!isAutoFill && (
          <Row wrap={false} gutter={[6, 16]} align="middle" className={styles.deleteContainer}>
            <Col flex="auto">
              <span className={styles.deleteParagraph}>
                Once you delete a status update, there is no going back. Please be certain.
              </span>
            </Col>
            <Col flex="none">
              <Popconfirm
                title="Are you sure you want to delete?"
                onConfirm={() => handleRemoveObservation()}
              >
                <Button danger>Delete</Button>
              </Popconfirm>
            </Col>
          </Row>
        )}
      </Modal>
    </div>
  );
};

export default EditObservation;
