import './styles/antd.less';
import * as Sentry from '@sentry/react';
import { ConfigProvider, Spin, App as AntdApp } from 'antd';
import {
  Navigate,
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromChildren,
  createRoutesFromElements,
  createSearchParams,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import React, {
  ReactElement, useEffect, useRef, useState,
} from 'react';
import { Content } from 'antd/es/layout/layout';
import ReactAppzi from 'react-appzi';
import { useAuth0 } from '@auth0/auth0-react';
import {
  Auth0Callback,
  Error404,
  Error500,
  ErrorBoundary,
  ForgotPassword,
  Login,
  Models,
  Redirect,
} from './pages';
import { FeatureContext, useClientCache, useLayout } from './context';
import { PROJECT_SLUG, REDIRECT_TO, routesKeyedById } from './config/constants';
import { RootRoute, RouteTracker, SiteLayout } from './components/atoms';
import AntdWrapper from './antdProvider';
import Files from './pages/Files';
import { OverridesStyles } from './overridesStyles';
import { client } from './services';
import config from './config';
import { getProjectIdFromUrl } from './utils/url';

interface RouteData {
  path: string,
  element: JSX.Element | null,
  requiresAuth?: boolean,
  showSiteLayout?: boolean,
}

const AssetUpdateTool = React.lazy(() => import('./pages/AssetUpdateTool'));
const Configuration = React.lazy(() => import('./pages/Configuration'));
const Asset = React.lazy(() => import('./pages/Asset'));
const UserManagement = React.lazy(() => import('./pages/UserManagement'));
const StatusUpdateTool = React.lazy(() => import('./pages/StatusUpdateTool'));
const PowerBI = React.lazy(() => import('./pages/PowerBI'));
const EmbedViewer = React.lazy(() => import('./pages/EmbedViewer'));

Sentry.init({
  dsn: config.sentryLogging.dsn,
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      ),
    }),
  ],
  tracesSampleRate: 1.0,
});

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(
  createBrowserRouter,
);

export default function App() {
  const [menuHeaderChildren, setMenuHeaderChildren] = useState<JSX.Element | null>(null);
  const [clientCache, clientCacheDispatch] = useClientCache();
  const [layout] = useLayout();
  const auth0 = useAuth0();
  const initialLoad = useRef(true);

  const { presenterMode } = layout;
  const baseRoutes: Array<RouteData> = [
    {
      path: '/files',
      element: <Files />,
      requiresAuth: true,
    },
    {
      path: '/files/:id',
      element: <Files />,
      requiresAuth: true,
    },
    {
      path: '/redirect',
      element: <Redirect />,
    },
    {
      path: '/500',
      element: <Error500 />,
    },
    {
      path: '/404',
      element: <Error404 />,
    },
    {
      path: '/forgot-password',
      element: <ForgotPassword />,
    },
    {
      path: '/auth0-callback',
      element: <Auth0Callback />,
    },
    {
      path: '/login',
      element: <Login />,
    },
    {
      path: '/logout',
      element: null,
    },
  ];

  const getMenuPage = (pageId: string) => {
    switch (pageId) {
      case 'status_tracking':
        return (
          <Asset
            renderMenuHeader={setMenuHeaderChildren}
          />
        );
      case 'user_management':
        return <UserManagement />;
      case 'asset_update_tool':
        return <AssetUpdateTool />;
      case 'status_update_tool':
        return <StatusUpdateTool />;
      case 'powerbi':
        return <PowerBI />;
      case 'models':
        return <Models />;
      case 'configuration':
        return <Configuration />;
      /** Custom Pages * */
      case 'custom-status-tracking':
        return (
          <Asset
            renderMenuHeader={setMenuHeaderChildren}
          />
        );
      case 'embed-viewer':
        return <EmbedViewer />;
      default:
        return <Error404 />;
    }
  };

  const menu = clientCache.clientCache?.bootstrap?.project.menu || [];
  const features = clientCache.clientCache?.bootstrap?.project.features || {};
  const menuRoutes = menu.map(i => i.name)
    .map((key) => {
      const { custom, customPath } = features[key];
      if (custom) {
        return {
          path: `${customPath}`,
          element: (
            <FeatureContext.Provider
              // eslint-disable-next-line react/jsx-no-constructed-context-values
              value={{ state: { assetManagerId: key } }}
            >
              {getMenuPage(custom)}
            </FeatureContext.Provider>
          ),
          requiresAuth: true,
          showSiteLayout: true,
        };
      }
      return {
        path: routesKeyedById.get(key)?.path,
        element: (
          <FeatureContext.Provider
            // eslint-disable-next-line react/jsx-no-constructed-context-values
            value={{ state: { assetManagerId: key } }}
          >
            {getMenuPage(key)}
          </FeatureContext.Provider>
        ),
        requiresAuth: true,
        showSiteLayout: true,
      };
    })
    .filter(i => !!i.path);
  const routes = [...baseRoutes, ...menuRoutes];

  useEffect(() => {
    const projectId = getProjectIdFromUrl();
    if (initialLoad.current && !['/redirect', '/login'].includes(window.location.pathname)) {
      initialLoad.current = false;
      client.initialize(auth0, projectId, clientCacheDispatch);
    }
  }, []);

  const loadingUserData = () => auth0.isLoading
    || (clientCache.clientCache?.auth?.isAuthenticated && !clientCache.clientCache?.bootstrap);

  useEffect(() => {
    if (clientCache.clientCache?.auth?.isAuthenticated && loadingUserData()) return;
    const { appziToken } = config.integrations;
    // ReactAppzi auto-rejects subsequent initialisations
    ReactAppzi.initialize(appziToken);
  }, [loadingUserData()]);

  useEffect(() => {
    // ReactAppzi auto-rejects subsequent initialisations
    const elementSli = document.querySelector('[id^="appzi-sli-"]');
    const elementSlo = document.querySelector('[id^="appzi-slo-"]');
    if (elementSlo && elementSli) {
      if (presenterMode) {
        (elementSlo as HTMLStyleElement).disabled = true;
        (elementSli as HTMLElement).style.display = 'none';
      } else {
        (elementSli as HTMLElement).style.display = 'block';
        (elementSlo as HTMLStyleElement).disabled = false;
      }
    }
  }, [presenterMode]);

  const navigateUnknownPage = () => {
    // We haven't determined if the user is authenticated yet
    if (loadingUserData()) return null;
    // If user is authenticated, tell them the page doesn't exist
    if (clientCache.clientCache?.bootstrap) return <Navigate to="/404" replace />;
    // The use is unauthenticated
    // Set a redirect back to the original page (+ url params) if user is unauthenticated
    const { pathname, search } = window.location;
    const loginSearchParams = createSearchParams({ [REDIRECT_TO]: `${pathname}${search}` });
    return <Navigate to={`/login?${loginSearchParams}`} replace />;
  };

  const authenticationWrapper = (child: ReactElement) => {
    if (loadingUserData()) {
      return null;
    }

    if (!client.cache.current.auth.isAuthenticated) {
      if (client.cache.current.auth.unauthenticatedReason !== 'user_unknown') {
        return <Navigate to="/logout" replace />;
      }
      return navigateUnknownPage();
    }

    return child;
  };

  const mapRoutes = () => routes.map((routeData) => {
    const {
      path, element, requiresAuth, showSiteLayout,
    } = routeData;

    if (requiresAuth) {
      return (
        <Route
          key={path}
          path={path}
          element={authenticationWrapper(
            <>
              <RouteTracker />
              <SiteLayout
                show={showSiteLayout}
                menuHeaderChildren={menuHeaderChildren}
              >
                <React.Suspense fallback={(
                  <Content
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Spin />
                  </Content>
                )}
                >
                  {element}
                </React.Suspense>
              </SiteLayout>
            </>,
          )}
        />
      );
    }

    return (
      <Route
        key={path}
        path={path}
        element={(
          <>
            <RouteTracker />
            {element}
          </>
        )}
      />
    );
  });

  const router = sentryCreateBrowserRouter(
    createRoutesFromElements(
      <Route errorElement={<ErrorBoundary />}>
        <Route path="/" element={<RootRoute isLoading={loadingUserData()} />} />
        <Route
          path={`/${PROJECT_SLUG}/`}
          element={(
            <RootRoute isLoading={loadingUserData()} />
)}
        />
        {mapRoutes()}
        <Route
          path="*"
          element={navigateUnknownPage()}
        />
      </Route>,
    ),
  );

  return (
    <ConfigProvider
      theme={{
        token: {
          borderRadius: 4,
          colorTextBase: '#000',
          colorText: '#000',
          colorTextSecondary: '#000',
          colorPrimary: '#1b55e3',
          colorSuccess: '#46be8a',
          colorWarning: '#f39834',
          colorError: '#fb434a',
          colorInfo: '#0887c9',
          wireframe: false,
          fontFamily: 'Inter,sans-serif',
        },
        components: {
          Radio: {
            buttonColor: '#999',
          },
          Button: {
            defaultColor: '#000',
          },
          Modal: {
            motionDurationMid: '0.4s',
            motionDurationSlow: '0.4s',
          },
          Card: {
            headerHeight: 48,
          },
        },
      }}
    >
      <AntdApp>
        <OverridesStyles />
        <AntdWrapper />
        <RouterProvider router={router} />
      </AntdApp>
    </ConfigProvider>
  );
}
