import { Button, Form, Input } from 'antd';
import React, { useState } from 'react';
import { client } from '../../services';
import { notification } from '../../antdProvider';
import style from './styles.module.less';

enum PageStatus {
  FORGOT_PASSWORD,
  SUCCESS_MESSAGE,
}

const ForgotPassword: React.FC = () => {
  const [passwordResetLoading, setPasswordResetLoading] = useState(false);
  const [pageStatus, setPageStatus] = useState(PageStatus.FORGOT_PASSWORD);
  const [form] = Form.useForm();
  const onSubmit = async () => {
    try {
      setPasswordResetLoading(true);
      const { email } = await form.validateFields();
      await client.server.post('/scratch/auth/reset-password', { email });
      setPageStatus(PageStatus.SUCCESS_MESSAGE);
    } catch (e) {
      notification.warning({
        message: 'Error',
        description: (e as Error).message,
      });
    } finally {
      setPasswordResetLoading(false);
    }
  };

  const renderResetForm = () => (
    <>
      <div className={style.titleName}>Forgot Password</div>
      <div className={style.hint}>Please enter your email address below.</div>
      <Form
        layout="vertical"
        hideRequiredMark
        onFinish={onSubmit}
        className={style.form}
        initialValues={{
          email: '',
        }}
        form={form}
      >
        <Form.Item
          name="email"
          rules={[{ required: true, message: 'Please input your e-mail address' }]}
        >
          <Input
            type="email"
            size="large"
            placeholder="Email"
            disabled={passwordResetLoading}
          />
        </Form.Item>
        <Button
          type="primary"
          size="large"
          htmlType="submit"
          loading={passwordResetLoading}
          className={style.submitBtn}
        >
          Submit
        </Button>
      </Form>
      <div className={style.bottom_actions_wrapper}>
        <a href="/login" className={style.bottomActionsLink}>
          &larr; Return To Login
        </a>
      </div>
    </>
  );

  const renderSuccessMessage = () => (
    <>
      <div className={style.titleName}>Check Your Inbox</div>
      <div className={style.hint}>
        We have sent an email to your inbox with further instructions.
      </div>
      <div className={style.bottom_actions_wrapper}>
        Still haven&#39;t received anything?
        <div
          onClick={() => setPageStatus(PageStatus.FORGOT_PASSWORD)}
          role="button"
          tabIndex={0}
        >
          {' Try again.'}
        </div>
      </div>
    </>
  );

  const renderBody = () => {
    switch (pageStatus) {
      case PageStatus.FORGOT_PASSWORD:
        return renderResetForm();
      case PageStatus.SUCCESS_MESSAGE:
        return renderSuccessMessage();
      default:
        return null;
    }
  };

  return (
    <div className={style.mainContainer}>
      <div className={style.container}>
        {
          renderBody()
        }
      </div>
    </div>
  );
};

export default ForgotPassword;
