import { ColumnFilterItem, FilterValue, SorterResult } from 'antd/es/table/interface';
import { compare, orderBy } from 'natural-orderby';
import { truncateMacAddress, uniqueListBy } from '@ynomia/core/dist/utils';
import { ColumnType } from 'antd/es/table';
import Icon from '@mdi/react';
import React from 'react';
import { Tag } from 'antd';
import { Type } from '@ynomia/core/dist/device';
import { mdiLinkVariant } from '@mdi/js';
import { formatObservationDate } from './time';
import ImagePreview from '../components/atoms/ImagePreview';
import {
  getLayer, getLoadNumber, getTextWidth, moveObjectToLast,
} from '.';
import {
  getFirstObservationDate, getFirstObservationExists, getFirstObservationField,
} from './observations';
import { ColorIndicator, TableRangeFilter } from '../components/atoms';
import {
  CUSTOM_DATE_COLUMNS,
  DEFAULT_PRETTY_MAC_ADDR_TRUNCATION,
} from '../config/constants';
import { Asset, AssetTableColumn, DataType } from '../config/types';

export const getColumnFilters = (
  tableFilter: Record<string, FilterValue | null>,
  tableSorter: SorterResult<DataType> | null,
  timezone: string | undefined,
  showSelectedOnly: boolean,
  listViewColumns: Array<AssetTableColumn>,
  assetData: Map<string, any>,
  filteredDataSource: Array<any>,
) => {
  const filteredAssetsSet = new Set(filteredDataSource.map(a => a.id));
  const sorter = (a: DataType, b: DataType, key) => {
    const valueA = a[key];
    const valueB = b[key];
    const customA = a.customSortFields?.[key];
    const customB = b.customSortFields?.[key];

    const customColumn = a.customColumnsKeyedByKey.get(key);
    if (CUSTOM_DATE_COLUMNS.includes(customColumn?.custom || '')) {
      return new Date(a[key] || 0).getTime() - new Date(b[key] || 0).getTime();
    }

    if (customColumn?.custom === 'tagLink') {
      return Number(!!valueB) - Number(!!valueA);
    }

    if (customA || customB) {
      return typeof customA === 'string' || typeof customB === 'string'
        ? compare()(customA, customB) : customA - customB;
    }

    return typeof valueA === 'string' || typeof valueB === 'string'
      ? compare()(valueA, valueB) : valueA - valueB;
  };

  const currentFeatureListViewColumns = listViewColumns;

  const sortedListView = orderBy(
    currentFeatureListViewColumns,
    [column => column.column_order?.full_view],
  );

  const columns = sortedListView?.map(
    (column) => {
      const { key, title, custom } = column;
      const filterEnabled = column.filter_enabled;
      const sortEnabled = column.sort_enabled;
      const fullView = column.column_order?.full_view;

      const getOpacity = recordId => (
        showSelectedOnly
        && !filteredAssetsSet.has(recordId) ? 0.5 : 1
      );

      const font = '.76rem Inter,sans-serif';
      const minWidth = (() => {
        // Antd doesn't handle long title correctly when table has overflow enabled.
        // We set our own minimum width based on the column title instead.
        // https://github.com/ant-design/ant-design/issues/31917
        let width = getTextWidth(title.toString(), font) + 15;
        if (sortEnabled) width += 16;
        if (filterEnabled) width += 40;
        return width;
      })();
      const placeholder = <div style={{ minWidth }} />;

      const columnOptions: ColumnType<DataType> = {
        title,
        dataIndex: key,
        key,
        fixed: fullView === 1,
        ellipsis: true,
        filteredValue: (tableFilter && tableFilter[key]) || null,
        sorter: sortEnabled ? (a, b) => sorter(a, b, key) : false,
        render: (value, record) => {
          const { id } = record;
          return (
            <div style={{ opacity: getOpacity(id), minWidth }}>
              {typeof value === 'string' ? value : ''}
            </div>
          );
        },
      };

      const blankOption = {
        text: '(blank)',
        value: '',
      };

      if (CUSTOM_DATE_COLUMNS.includes(custom || '')) {
        columnOptions.render = (value, record) => {
          const { id } = record;
          if (!value) return placeholder;
          const displayedStatusUpdatedDate = `${formatObservationDate(value, timezone)}`;
          return (
            <div style={{ opacity: getOpacity(id), minWidth }}>
              {`${displayedStatusUpdatedDate}`}
            </div>
          );
        };
      }

      if (custom === 'tagLink') {
        columnOptions.filters = [
          {
            text: 'Currently Linked',
            value: true,
          },
          {
            text: 'Currently Not Linked',
            value: false,
          },
        ];
        columnOptions.render = (value, record) => {
          const { id } = record;
          return (
            <div style={{
              display: 'flex',
              justifyContent: 'center',
              opacity: getOpacity(id),
              minWidth: minWidth + 15,
            }}
            >
              <div style={{ marginTop: 3 }}>
                <Icon
                  path={mdiLinkVariant}
                  size={0.7}
                  color={value ? '#46be8a' : '#666666'}
                />
              </div>
              <div style={{ marginLeft: 4 }}>{value}</div>
            </div>
          );
        };
      }

      if (key === 'status') {
        columnOptions.render = (value, record) => {
          const { color } = record;
          return (
            <div style={{
              display: 'flex',
              minWidth,
            }}
            >
              {value && (
                <Tag
                  style={{ fontSize: 14 }}
                  icon={(
                    <ColorIndicator
                      color={color}
                      isRowSelected
                      style={{
                        height: 10, width: 10, marginRight: 4, marginBottom: 2,
                      }}
                    />
                  )}
                >
                  {value}
                </Tag>
              )}
            </div>
          );
        };
      }

      if (custom === 'observationTypePhotos') {
        columnOptions.render = (value) => {
          if (!value) return placeholder;
          const preview = v => <ImagePreview thumbnail key={v} src={v} useYnomiaAccessToken />;
          if (Array.isArray(value)) {
            return (
              <div style={{ display: 'flex', flexDirection: 'row', minWidth }}>
                {value?.map?.(preview)}
              </div>
            );
          }
          return preview(value);
        };
      }

      if (filterEnabled) {
        let filterOptions: Array<ColumnFilterItem> = [];
        if (CUSTOM_DATE_COLUMNS.includes(custom || '')) {
          columnOptions.filterDropdown = (filterDropdownProps) => {
            const {
              setSelectedKeys,
              confirm,
              clearFilters,
              close,
              selectedKeys,
              visible,
            } = filterDropdownProps;

            return (
              <TableRangeFilter
                setSelectedKeys={setSelectedKeys}
                selectedKeys={selectedKeys}
                confirm={confirm}
                clearFilters={() => clearFilters}
                close={close}
                visible={visible}
              />
            );
          };
        } else {
          assetData?.forEach((data) => {
            if (data[key]) {
              filterOptions.push({
                text: data[key],
                value: data[key],
              });
            } else {
              filterOptions.push(blankOption);
            }
          });
          filterOptions = orderBy(uniqueListBy(filterOptions, 'value'), [v => v.value]);
          columnOptions.filters = moveObjectToLast(
            filterOptions,
            blankOption,
          ) as ColumnFilterItem[];
        }
      }

      return columnOptions;
    },
  );

  return columns.map((col): ColumnType<DataType> => ({
    ...col,
    sortOrder: tableSorter?.columnKey === col.key ? tableSorter?.order : null,
  }));
};

/**
 * This function compiles an asset so that all asset data is
 * accessible by keying the output object.
 * The data is however, not formatted yet so we can still apply
 * manual sorting and filtering for numbers and dates.
 * @returns Compiled Asset Data
 */
export const getDataSourceAssetFromAsset = (
  asset: Asset,
  customColumns,
  layersKeyedById,
  assetsKeyedById,
  assetStatusesKeyedById,
  linkedDevicesKeyedByAssetId,
) => {
  const {
    id, fields, status, destination, slots, observations,
  } = asset;
  const { parents } = slots || {};
  const layerID = destination?.layerID || null;
  const statusOrder = (status && assetStatusesKeyedById.get(status)?.order) || 0;

  const customFields = {};
  const customColumnsKeyedByKey = new Map(customColumns.map(column => [column.key, column]));
  const customSortFields = {
    status: statusOrder,
  };

  customColumns.forEach(({
    key, custom, layerType, parentSlot, observationType,
  }) => {
    switch (custom) {
      case 'matchLayerType':
        customFields[key] = getLayer(layersKeyedById, layerID, layerType);
        break;
      case 'observationTypeExists':
        customFields[key] = getFirstObservationExists(observations, observationType);
        break;
      case 'observationTypeDate':
        customFields[key] = getFirstObservationDate(observations, observationType);
        break;
      case 'observationTypeField':
        customFields[key] = getFirstObservationField(observations, observationType, key);
        break;
      case 'observationTypePhotos':
        customFields[key] = getFirstObservationField(observations, observationType, key);
        break;
      case 'parentSlot':
        customFields[key] = getLoadNumber(assetsKeyedById, fields, key, parents, parentSlot);
        break;
      case 'tagLink': {
        const device = linkedDevicesKeyedByAssetId.get(id) || undefined;
        const { addr, type, foreignID } = device || {};
        if (!device) customFields[key] = undefined;
        else if (device && type === Type.MINEW) customFields[key] = foreignID;
        else {
          customFields[key] = truncateMacAddress(
            addr,
            DEFAULT_PRETTY_MAC_ADDR_TRUNCATION,
            false,
          );
        }
        break;
      }
      default:
    }
  });

  const res = {
    ...asset,
    ...fields,
    ...customFields,
    layerID: destination?.layerID || null,
    twinID: destination?.twinID?.[0] || null,
    key: id,
    status: assetStatusesKeyedById.get(status)?.label ?? '',
    color: assetStatusesKeyedById.get(status)?.color,
    statusOrder,
    customColumnsKeyedByKey,
    customSortFields,
  };
  return res;
};
