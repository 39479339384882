import React, { createContext, FC, useContext } from 'react';

export type FeatureStateType = {
  assetManagerId: string
};

const initialState: FeatureStateType = {
  assetManagerId: 'status_tracking',
};

export const FeatureContext = createContext({ state: initialState });

export const useFeature = (): [FeatureStateType] => {
  const { state } = useContext(FeatureContext);
  return [state];
};

type Props = React.PropsWithChildren<{ value: { state: FeatureStateType; } }>;

export const FeatureProvider: FC<Props> = ({ value, children }) => (
  <FeatureContext.Provider
    value={value}
  >
    {children}
  </FeatureContext.Provider>
);
