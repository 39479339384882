import { SET_FILE_ERROR, SET_IMG_SRC } from './actionTypes';
import { getImageById, getImageByUrl } from '../services';
import { ContextDispatch } from '../config/types';

export const setImgSrc = (
  imgSrc: string,
  dispatch: ContextDispatch,
) => dispatch({ type: SET_IMG_SRC, payload: imgSrc });

export const setFileError = (error, dispatch: ContextDispatch) => dispatch({
  type: SET_FILE_ERROR,
  payload: error,
});

export const clearError = (dispatch: ContextDispatch) => dispatch({
  type: SET_FILE_ERROR,
  payload: {
    message: '',
    code: '',
  },
});

export const getFile = (url: string | null, id: string | null, dispatch: ContextDispatch) => {
  const handleResponse = (response: Response) => {
    const { status } = response;
    switch (status) {
      case 200:
        clearError(dispatch);
        response.blob().then((blob) => {
          setImgSrc(URL.createObjectURL(blob), dispatch);
        });
        break;
      default:
        response.json().then(({ err }) => {
          setFileError({
            message: err.msg,
            code: err.code,
          }, dispatch);
        });
    }
  };

  if (url) {
    getImageByUrl(url).then(handleResponse);
  }
  if (id) {
    getImageById(id).then(handleResponse);
  }
};
