import Papaparse from 'papaparse';
import { report as coreReport } from '@ynomia/core';
import { Asset, AssetTableColumn } from '../config/types';
import { CUSTOM_DATE_COLUMNS } from '../config/constants';
import { formatDate } from '.';

import { getAccessibleImageUrl } from '../services';

export const generateFile = (
  data,
  filename = `export_${new Date().getTime()}.csv`,
  type = 'text/csv',
) => {
  // Create an invisible A element
  const a = document.createElement('a');
  a.style.display = 'none';
  document.body.appendChild(a);

  // Set the HREF to a Blob representation of the data to be downloaded
  a.href = window.URL.createObjectURL(new Blob([data], { type }));

  // Use download attribute to set set desired file name
  a.setAttribute('download', filename);

  // Trigger the download by simulating click
  a.click();

  // Cleanup
  window.URL.revokeObjectURL(a.href);
  document.body.removeChild(a);
};

export const generateDataWithFilteredAsset = (
  data,
  filteredAssets: Array<Asset>,
): string => {
  if (!data) {
    return '';
  }
  const commonConfig = { delimiter: ',' };

  const labels = filteredAssets.map(({ label }) => label);
  const result = Papaparse.parse(data, { ...commonConfig });
  const jsonData = result.data as Array<Array<string>>;
  const header = result.data[0] as Array<string>;
  const filteredData = jsonData.filter(row => labels.includes(row[0]));

  return Papaparse.unparse(
    [header, ...filteredData],
    { newline: '\r\n' },
  );
};

export const formatReportType = (type: string): string => {
  let formattedType: string = type;

  if (type.includes('_')) {
    const typeWords: Array<string> = type.replace('_', ' ').split(' ');
    formattedType = typeWords
      .map(word => word[0].toUpperCase() + word.substring(1))
      .join(' ');
  } else {
    formattedType = type.charAt(0).toUpperCase() + type.slice(1);
  }
  return `${formattedType} Exports`;
};

export const generateCSV = (
  header: Array<string>,
  data: Array<Array<string>>,
) => Papaparse.unparse(
  [header, ...data],
  { newline: '\r\n' },
);

export const getVisibleViewCSV = (dataSource, listViewColumns: Array<AssetTableColumn>) => {
  const headerTitles: Array<string> = listViewColumns.map(({ title }) => `${title}`);
  const headerKeys = listViewColumns.map(({ key }) => key as string);
  const listViewColumnsByKey = new Map(listViewColumns.map(column => [column.key, column]));

  const res = dataSource.map(data => headerKeys.map((key) => {
    const custom = listViewColumnsByKey.get(key)?.custom || null;
    const value = data[key];
    if (CUSTOM_DATE_COLUMNS.includes(custom || '')) {
      return `${formatDate(value)}`;
    }
    if (custom === 'observationTypePhotos') {
      return value?.map?.(getAccessibleImageUrl).join(', ');
    }
    return value;
  }));

  return generateCSV(headerTitles, res);
};

export const getReportConfig = (
  key: string,
  tenant: string,
  projectCode: string,
): coreReport.ReportConfigV2 => ({
  report_name: key,
  tenant,
  project: projectCode,
  csv: '1',
});

export const getExportKey = (category, name) => `${category}_${name}`;
