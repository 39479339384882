import {
  Avatar, Button, Dropdown, MenuProps, Row, Space,
} from 'antd';
import { DownOutlined, UserOutlined } from '@ant-design/icons';
import React, { FC, useEffect, useState } from 'react';
import { Header } from 'antd/es/layout/layout';
import { Link } from 'react-router-dom';
import { UserInfo } from '@ynomia/client';
import { REDIRECT_TO, USER_INITIATED } from '../../../config/constants';
import { client, getCurrentRoute } from '../../../services';
import {
  getProjectName,
  getProjects,
  getUserInfo,
} from '../../../selectors';
import { SideMenu } from '../../atoms';
import YnomiaFullLogoDark from '../../../images/ynomia-full-logo-dark.svg?react';
import { getContextStores } from '../../../context';
import styles from './styles.module.less';

const MenuHeader: FC<React.PropsWithChildren<{}>> = ({ children }) => {
  /* Context  */
  const contextStores = getContextStores();
  const { layoutState } = contextStores;
  const { presenterMode } = layoutState;
  /* Selectors */
  const projectName = getProjectName(contextStores);
  const userInfo = getUserInfo(contextStores);
  const projects = getProjects(contextStores);

  const [width, setWidth] = useState(window.innerWidth);

  const { picture } = userInfo as UserInfo;

  let menuProjects: MenuProps['items'] = projects?.filter(
    ({ isArchived }) => !isArchived,
  ).map(({ name, id, tenant }) => ({
    label: `${name} (${tenant})`.toUpperCase(),
    key: id,
  }));

  const menuArchivedProjects: MenuProps['items'] = projects?.filter(
    ({ isArchived }) => isArchived,
  ).map(({ name, id, tenant }) => ({
    label: `${name} (${tenant})`.toUpperCase(),
    key: id,
  }));

  if (menuArchivedProjects.length) {
    menuProjects = [...menuProjects,
      {
        label: <span className={styles.archivedLabel}>Archived</span>,
        key: 'archived',
        children: menuArchivedProjects,
      }];
  }

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [width]);

  const onClick: MenuProps['onClick'] = (e) => {
    const requestedProjectId = e.key;
    if (!requestedProjectId) return;
    const currentRoute = getCurrentRoute();
    const newPath = currentRoute?.path;

    window.location.href = `/${requestedProjectId}?`
      + `${REDIRECT_TO}=${encodeURIComponent(newPath)}`;
  };

  const renderLeftCol = () => (
    <>
      <div className={styles.title}>
        {!presenterMode && <SideMenu />}
        <Link to="/">
          <YnomiaFullLogoDark style={{ width: 130 }} id="ynomia-logo-menu-header" />
        </Link>
      </div>
      <div className={styles.menuProject}>
        <Dropdown menu={{ onClick, items: menuProjects }} placement="bottomLeft">
          <Button style={{ height: 40 }}>
            {projectName?.toUpperCase() || ''}
            <DownOutlined />
          </Button>
        </Dropdown>
      </div>
    </>
  );

  const renderRightCol = () => (
    <div className={styles.headerRight}>
      <Dropdown
        menu={{
          items: [{
            key: '1',
            label: (
              <div
                onClick={() => client.logout(USER_INITIATED)}
                role="button"
                tabIndex={0}
              >
                Logout
              </div>
            ),
          }],
        }}
        placement="bottomRight"
      >
        <Space>
          <Avatar
            className={styles.avatar}
            shape="circle"
            size="large"
            icon={<UserOutlined />}
            src={picture}
          />
          <DownOutlined />
        </Space>
      </Dropdown>
    </div>
  );

  const renderHeaderOnMultipleLine = () => (
    <div>
      <Row className={styles.containerMultiple} gutter={[16, 4]}>
        {renderLeftCol()}
        {renderRightCol()}
      </Row>
      {!presenterMode && (
      <div style={{ paddingLeft: 20, paddingRight: 20, marginBottom: 15 }}>
        {children}
      </div>
      )}
    </div>
  );

  const renderHeader = () => (
    <div className={styles.container}>
      {renderLeftCol()}
      <div className={styles.childrenColumn}>{children}</div>
      <div style={{ marginRight: 15, display: 'flex' }}>
        {renderRightCol()}
      </div>
    </div>
  );

  if (presenterMode) {
    return (
      <Header className={styles.headerPresenterMode}>
        <div className={styles.containerPresenterMode}>
          <div className={styles.title}>
            <Link to="/">
              <YnomiaFullLogoDark style={{ width: 130 }} id="ynomia-logo-menu-header" />
            </Link>
          </div>
          <span className={styles.projectTitlePresenterMode}>
            {projectName.toUpperCase() || ''}
          </span>
          {children}
        </div>
      </Header>
    );
  }

  return (
    <Header className={styles.header}>
      {width > 1239 ? renderHeader() : renderHeaderOnMultipleLine()}
    </Header>
  );
};

export default MenuHeader;
