import { useState } from 'react';

export type ExpandingTimelineType = {
  isAllExpanded: () => boolean,
  toggleAllExpanded: () => void,
  expandedRowKeys: Array<string | number>,
  toggleRowExpanded: (key: string) => void,
};

export const useExpandingTimeline = (
  keys: Array<string | number>,
  keyName: string,
  defaultExpanded: boolean,
) => {
  const [expandedKeys, setExpandedKeys] = useState(defaultExpanded ? keys : []);
  const isAllExpanded = () => (
    expandedKeys.length
    && expandedKeys.length
    && expandedKeys.length === keys.length
  ) || false;

  const toggleRowExpanded = (key) => {
    if (expandedKeys.includes(key)) {
      setExpandedKeys(expandedKeys.filter(item => item !== key));
    } else {
      setExpandedKeys(expandedKeys.concat([key]));
    }
  };

  const toggleAllExpanded = () => {
    if (isAllExpanded()) {
      setExpandedKeys([]);
    } else {
      setExpandedKeys(keys);
    }
  };

  return {
    isAllExpanded,
    toggleAllExpanded,
    expandedRowKeys: expandedKeys,
    toggleRowExpanded,
  };
};
