import { ICache } from '@auth0/auth0-react';
import { InMemoryCache } from '@ynomia/client';

export interface Auth0Data {
  body: {
    access_token: string
    audience: string
    client_id: string
    decodedToken: {
      encoded: {
        header: string,
        payload: string,
        signature: string,
      }
      header: {
        alg: string
        typ: string
        kid: string
      }
      claims: {
        __raw: string
        nickname: string
        name: string
        picture: string
        updated_at: string
        email: string
        email_verified: boolean
        iss: string
        sub: string
        aud: string
        iat: number
        exp: number
        nonce: string
      }
      user: {
        nickname: string
        name: string
        picture: string
        updated_at: string
        email: string
        email_verified: boolean
        sub: string
      }
    }
    expires_in: number
    id_token: string
    oauthTokenScope: string
    refresh_token: string
    scope: string
    token_type: 'Bearer'
  }
  expiresAt: number
}

// https://community.auth0.com/t/how-to-retrieve-refresh-token-using-auth0-auth0-react/69700
class Auth0Cache implements ICache {
  private auth0Cache = new InMemoryCache();

  private auth0Data: Auth0Data | undefined;

  get(key: string) {
    return this.auth0Cache.current[key];
  }

  set(key: string, value: any) {
    if (value?.body?.access_token && value?.body?.client_id && value?.body?.refresh_token) {
      this.auth0Data = {
        ...this.auth0Data,
        ...value,
        body: { ...this.auth0Data?.body, ...value.body },
      };
    }

    // auth0-react 2.x.x returns the id_token in a separate request
    // We concat the two payloads to maintain support for our existing @ynomia/client
    if (value?.id_token) {
      this.auth0Data = {
        ...this.auth0Data!,
        body: {
          ...this.auth0Data?.body!,
          id_token: value.id_token,
        },
      };
    }

    this.auth0Cache.assign({ [key]: value });
  }

  remove(key: string) {
    this.auth0Cache.unset(key);
  }

  get cache(): InMemoryCache {
    return this.auth0Cache;
  }

  get data(): Auth0Data | undefined {
    return this.auth0Data;
  }
}

const auth0Cache = new Auth0Cache();

export default auth0Cache;
