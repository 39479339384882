import { Button, MenuProps, Dropdown } from 'antd';
import React, { FC } from 'react';
import { DownOutlined } from '@ant-design/icons';
import styles from './styles.module.less';

interface Props {
  menu: MenuProps | undefined;
  label: string | undefined | null;
  size?: 'small' | 'default';
}

const DropdownFilter: FC<Props> = ({ menu, label, size = 'default' }) => {
  if (!label) {
    return null;
  }
  return (
    <div className={styles.dropDownFilterContainer}>
      <Dropdown menu={menu}>
        <Button
          className={
            size === 'small'
              ? styles.dropDownFilterButtonSmall
              : styles.dropDownFilterButton
          }
        >
          {label}
          {' '}
&nbsp;
          <DownOutlined className={styles.dropDownFilterDownOutlined} />
        </Button>
      </Dropdown>
    </div>
  );
};

export default DropdownFilter;
