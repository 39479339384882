import { isEmpty } from 'lodash';
import { AssetFilter } from '../context';

export const assetFilterIsActive = (assetManagerFilters: AssetFilter) => {
  const {
    searchTags,
    searchText,
    assetStatusFilter,
    tableFilter,
    openedLayerId,
    selectedLayerIds,
    supplierFilter,
    observationTypeFilter,
    dateFilter,
  } = assetManagerFilters;
  return !!(
    searchTags?.length
    || searchText
    || assetStatusFilter?.toggle
    || !Object.values(tableFilter).every(element => element === null || !element.length)
    || openedLayerId
    || selectedLayerIds?.length
    || supplierFilter?.length
    || !isEmpty(observationTypeFilter)
    || dateFilter[0] || dateFilter[1]
  );
};

export const anyFilterIsActive = (assetManagerFilters: AssetFilter, defaultAssetType?: string) => {
  const {
    typeFilter,
    supplierFilter,
    timeTravelDate,
    pagination,
    tableSorter,
  } = assetManagerFilters;
  return !!(
    assetFilterIsActive(assetManagerFilters)
    || (typeFilter !== defaultAssetType)
    || supplierFilter?.length
    || timeTravelDate
    || tableSorter?.order
    || pagination
  );
};
