import { ContextDispatchParam, Layer } from '../config/types';
import { SET_FETCHING_LAYERS, SET_LAST_FETCH_START_TIME, SET_LAYERS } from '../actions/actionTypes';
import { LayerStateType } from '../context';

export default (
  state: LayerStateType,
  action: ContextDispatchParam,
): LayerStateType => {
  switch (action.type) {
    case SET_LAYERS: {
      // Only update the state when there's a layer change
      if (action.payload?.size === 0) return state;
      const layersKeyedById = new Map<string, Layer>([
        ...state.layersKeyedById,
        ...action.payload,
      ]);

      layersKeyedById.forEach(
        ({ isArchived, id }) => isArchived && layersKeyedById.delete(id),
      );
      return {
        ...state,
        layersKeyedById: action.payload,
      };
    }
    case SET_FETCHING_LAYERS:
      return {
        ...state,
        isFetchingLayers: action.payload,
      };
    case SET_LAST_FETCH_START_TIME:
      return {
        ...state,
        lastFetchStartTime: action.payload,
      };
    default:
      return state;
  }
};
