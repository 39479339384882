import { DatePicker, TimePicker } from 'antd';
import React, { useEffect, useState } from 'react';
import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import localeData from 'dayjs/plugin/localeData';
import {
  getProjectTodayLocalized,
  isTimestampMidnight,
} from '../../../../utils';

dayjs.extend(localeData);
dayjs.extend(isToday);

interface Props {
  restriction?: 'past' | 'future';
  format?: string,
  value?: Dayjs | null;
  style?: React.CSSProperties;
  onChange: (value: Dayjs | null) => void;
  disabled: boolean
  allowClear: boolean
}

const DateTimeSplit: React.FC<Props> = ({
  restriction,
  onChange,
  value,
  disabled,
  format,
  allowClear,
  style,
}) => {
  const formattedDateValue = value ?? null;

  const [date, setDate] = useState(formattedDateValue);
  const [time, setTime] = useState(formattedDateValue);
  const onChangeHandler = (newVal: Dayjs, type = 'date') => {
    if (type === 'time') {
      let combined: Dayjs;
      if (newVal === null) {
        combined = dayjs(date || 0).hour(0).minute(0).second(0)
          .millisecond(0);
      } else {
        combined = dayjs(date || 0)?.hour(newVal?.hour())?.minute(newVal?.minute());
      }
      setTime(combined);
      if (date) setDate(combined);
    } else if (!date && time) {
      const combined = dayjs(newVal)?.hour(time?.hour())?.minute(time?.minute());
      setDate(combined);
    } else {
      /**
       * We often want the user to reselect the
       * time if they change the date to a different date.
       * @TODO - If needed, add a prop for this
       */
      if (time?.isToday() && !newVal.isToday()) {
        setTime(null);
      }
      setDate(newVal);
    }
  };

  useEffect(() => {
    if (!date) return;
    onChange(date);
  }, [date, time]);

  const range = (start: number, end: number) => {
    const result: Array<number> = [];
    for (let i = start; i < end; i += 1) {
      result.push(i);
    }
    return result;
  };

  const projectTodayLocalised = getProjectTodayLocalized();

  const disabledDateTime = (temp: Dayjs) => {
    const ref = temp ?? date;
    if (restriction === 'past' && dayjs(date)?.isToday()) {
      return {
        disabledHours: () => range(projectTodayLocalised.hour() + 1, 24),
        disabledMinutes: () => (dayjs(ref).hour() === projectTodayLocalised.hour()
          ? range(projectTodayLocalised.minute() + 1, 60) : []),
      };
    } if (restriction === 'future' && dayjs(ref)?.isToday()) {
      return {
        disabledHours: () => (projectTodayLocalised.isToday()
          ? range(0, projectTodayLocalised.hour() + 1)
          : []),
        disabledMinutes: () => (dayjs(ref).hour() === projectTodayLocalised.hour()
          ? range(0, projectTodayLocalised.minute() + 1)
          : []),
      };
    }
    return {};
  };

  return (
    <>
      <DatePicker
        onChange={newVal => onChangeHandler(newVal as Dayjs)}
        format={format || 'DD/MM/YYYY'}
        disabled={disabled}
        style={{ width: '100%' }}
        value={date}
        maxDate={restriction === 'past' ? projectTodayLocalised : undefined}
        minDate={restriction === 'future' ? projectTodayLocalised : undefined}
        allowClear={allowClear}
      />
      <div style={{ marginTop: '1em', marginBottom: '1em' }}><span>Time</span></div>
      <TimePicker
        onChange={(newVal) => {
          onChangeHandler(newVal, 'time');
        }}
        format="hh:mma"
        disabled={disabled}
        style={{ width: '100%', ...style }}
        value={time && isTimestampMidnight(time?.toDate()) ? undefined : time}
        allowClear
        disabledTime={disabledDateTime}
      />
    </>
  );
};

export default DateTimeSplit;
