import React, {
  createContext, FC, useContext, useReducer,
} from 'react';
import { FilterValue } from 'antd/es/table/interface';
import { AccessRule, ContextDispatch } from '../config/types';
import AccessRuleReducer from '../reducers/accessRules';

export type AccessRuleStateType = {
  accessRulesKeyedById: Map<string, AccessRule>
  isFetchingAccessRules: boolean
  isCreatingNewAccessRule: boolean
  isUpdatingAccessRule: boolean
  searchAccessRules: string | null
  filtersAccessRulesTable: Record<string, FilterValue | null>
};

const initialState: AccessRuleStateType = {
  accessRulesKeyedById: new Map(),
  searchAccessRules: null,
  isFetchingAccessRules: false,
  isCreatingNewAccessRule: false,
  isUpdatingAccessRule: false,
  filtersAccessRulesTable: {},
};

export const AccessRuleContext = createContext({
  state: initialState,
  dispatch: (() => { }) as ContextDispatch,
});

export const useAccessRule = (): [AccessRuleStateType, ContextDispatch] => {
  const { state, dispatch } = useContext(AccessRuleContext);
  return [state, dispatch];
};

export const AccessRuleProvider:FC<React.PropsWithChildren<{}>> = ({ children }) => {
  const [state, dispatch] = useReducer(AccessRuleReducer, initialState);

  return (
    <AccessRuleContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        state,
        dispatch,
      }}
    >
      {children}
    </AccessRuleContext.Provider>
  );
};
