import { ACCESS_TOKEN_STORAGE_KEY } from '../config/constants';
import config from '../config';

export async function getImageById(id: string): Promise<any> {
  return getImageByUrl(`${config.host.api}/files/${id}.jpeg`);
}

export function getAccessibleImageUrl(url: string) {
  return `${window.location.origin}/files?url=${encodeURIComponent(url)}`;
}

export async function getImageByUrl(url: string): Promise<any> {
  const accessToken = localStorage.getItem(ACCESS_TOKEN_STORAGE_KEY);
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  return response;
}
