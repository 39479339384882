import React, {
  createContext, FC, useContext, useReducer,
} from 'react';
import { Device } from '@ynomia/core/dist/device';
import { ContextDispatch } from '../config/types';
import DeviceReducer from '../reducers/devices';

export type DeviceStateType = {
  devicesKeyedById: Map<string, Device>
  isFetchingDevices: boolean
};

const initialState: DeviceStateType = {
  devicesKeyedById: new Map(),
  isFetchingDevices: false,
};

export const DeviceContext = createContext({
  state: initialState,
  dispatch: (() => { }) as ContextDispatch,
});

export const useDevice = (): [DeviceStateType, ContextDispatch] => {
  const { state, dispatch } = useContext(DeviceContext);
  return [state, dispatch];
};

export const DeviceProvider:FC<React.PropsWithChildren<{}>> = ({ children }) => {
  const [state, dispatch] = useReducer(DeviceReducer, initialState);

  return (
    <DeviceContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        state,
        dispatch,
      }}
    >
      {children}
    </DeviceContext.Provider>
  );
};
