import { AssetType } from '@ynomia/core/dist/blueprint';
import { RequestError } from '@ynomia/client';
import { Asset, ContextDispatch } from '../config/types';
import {
  SET_ASSETS,
  SET_FETCHING_ASSETS,
  SET_INITIAL_FETCH_COMPLETE,
  SET_LAST_FETCH_ERROR,
  SET_LAST_FETCH_START_TIME,
  SET_UPDATING_BULK_OBSERVATION,
  SET_UPDATING_OBSERVATION,
} from './actionTypes';
import {
  batchUpdateAssetsStatus,
  deleteObservation,
  fetchAssets,
  patchObservationDate,
  updateAssetStatus,
} from '../services';
import { notification } from '../antdProvider';

export const setFetchingAssets = (
  status: boolean,
  dispatch: ContextDispatch,
) => dispatch({ type: SET_FETCHING_ASSETS, payload: status });

export const setLastFetchStartTime = (
  date: Date,
  dispatch: ContextDispatch,
) => dispatch({ type: SET_LAST_FETCH_START_TIME, payload: date });

export const setFirstFetchAsset = (
  dispatch: ContextDispatch,
) => dispatch({ type: SET_INITIAL_FETCH_COMPLETE });

export const setUpdatingObservation = (
  status: boolean,
  dispatch: ContextDispatch,
) => dispatch({ type: SET_UPDATING_OBSERVATION, payload: status });

export const setUpdatingBulkObservation = (
  status: boolean,
  dispatch: ContextDispatch,
) => dispatch({ type: SET_UPDATING_BULK_OBSERVATION, payload: status });

export const getAssets = async (
  tenant: string,
  projectCode: string,
  assetTypes: Array<AssetType>,
  modifiedAfter: Date | null,
  dispatch: ContextDispatch,
) => {
  setFetchingAssets(true, dispatch);
  let assetUpdates: Map<string, Asset> | undefined;
  try {
    const lastFetchStartTime = new Date();
    const res = await fetchAssets(tenant, projectCode, assetTypes, modifiedAfter);
    assetUpdates = new Map(res.map(asset => [asset.id, asset]));
    dispatch({
      type: SET_ASSETS,
      payload: assetUpdates,
    });
    setLastFetchStartTime(lastFetchStartTime, dispatch);
  } catch (error) {
    const { status, body } = error as RequestError;

    if (status || body) {
      notification.error({
        message: status,
        description: body?.msg || '',
      });
    }
    dispatch({ type: SET_LAST_FETCH_ERROR });
  }
  setFetchingAssets(false, dispatch);
  if (!modifiedAfter) {
    setFirstFetchAsset(dispatch);
  }
  return assetUpdates;
};

export const updateObservationDate = async (
  observationID: string,
  tenant: string,
  projectCode: string,
  date: Date,
  assetTypes: Array<AssetType>,
  modifiedAfter: Date | null,
  dispatch: ContextDispatch,
) => {
  try {
    setUpdatingObservation(true, dispatch);
    await patchObservationDate(tenant, projectCode, observationID, date);
    await getAssets(tenant, projectCode, assetTypes, modifiedAfter, dispatch);
    setUpdatingObservation(false, dispatch);
  } catch (error) {
    const { status, body } = error as RequestError;
    notification.error({
      message: status,
      description: body?.msg || '',
    });
    setUpdatingObservation(false, dispatch);
  }
};

export const addNewObservation = async (
  tenant: string,
  projectCode: string,
  assetTypes: Array<AssetType>,
  payload: { [key: string]: any },
  modifiedAfter: Date | null,
  dispatch: ContextDispatch,
) => {
  try {
    await updateAssetStatus(tenant, projectCode, payload);
    await getAssets(tenant, projectCode, assetTypes, modifiedAfter, dispatch);
  } catch (error) {
    const { status, body } = error as RequestError;
    notification.error({
      message: status,
      description: body?.msg || '',
    });
  }
};

export const removeObservation = async (
  observationID: string,
  tenant: string,
  projectCode: string,
  assetTypes: Array<AssetType>,
  modifiedAfter: Date | null,
  dispatch: ContextDispatch,
) => {
  try {
    await deleteObservation(tenant, projectCode, observationID);
    await getAssets(tenant, projectCode, assetTypes, modifiedAfter, dispatch);
  } catch (error) {
    const { status, body } = error as RequestError;
    notification.error({
      message: status,
      description: body?.msg || '',
    });
  }
};

export const updateBulkObservation = async (
  tenant: string,
  projectCode: string,
  selectedAssets: Array<string>,
  statusId: string,
  date: Date,
  isBackdated: boolean,
  assetTypes: Array<AssetType>,
  modifiedAfter: Date | null,
  dispatch: ContextDispatch,
) => {
  try {
    setUpdatingBulkObservation(true, dispatch);
    await batchUpdateAssetsStatus(
      tenant,
      projectCode,
      selectedAssets,
      statusId,
      date,
      isBackdated,
    );
    await getAssets(tenant, projectCode, assetTypes, modifiedAfter, dispatch);
  } catch (error) {
    const { status, body } = error as RequestError;
    notification.error({
      message: status,
      description: body?.msg || '',
    });
  } finally {
    setUpdatingBulkObservation(false, dispatch);
  }
};
